import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { CARD_PURCHASE_STATUS, SUBSCRIPTION_STATUS, UserSubscriptionStatusDto } from 'src/app/models/card';
import { MockDataService } from './mock-data.service';

const users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User' }];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    constructor(
      public userDataService: UserDataService,
      public mockDataService: MockDataService
      ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;


      const getRecentUserData =  () => {
        return this.userDataService.getMockPurchasedCards();
      }

      const addRecentPurchase = (recentPurchaseId) => {
        this.userDataService.addToMockPurchasedCards(recentPurchaseId)
      }

      const getUserReviews = () => {
        const userReviewPublishRejectModels = JSON.parse(JSON.stringify(this.mockDataService.mockPublishedModels));
        userReviewPublishRejectModels.forEach(model => model.purchaseStatus = CARD_PURCHASE_STATUS.PUBLISHED);
        const publishedLength = userReviewPublishRejectModels.length;
        userReviewPublishRejectModels[publishedLength-2].purchaseStatus = CARD_PURCHASE_STATUS.IN_REVIEW
        userReviewPublishRejectModels[publishedLength-1].purchaseStatus = CARD_PURCHASE_STATUS.REJECTED
        return ok(userReviewPublishRejectModels);
      }

      const getResponseSenToReview = () => {
        console.log(body);
        return ok({});
      }

      const postActivateTrial = () => {

        console.log('** post activate trial intercepted',body);
        return ok({});
      }

      const getResponseModelById = () => {
        console.log(body);
        return ok({});
      }

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                // case url.endsWith('/users/authenticate') && method === 'POST':
                //     return authenticate();
                // case url.endsWith('/users') && method === 'GET':
                //     return getUsers();
                // case url.endsWith('/getUserReviews') && method === 'GET':
                //   return getUserReviews();
                // getListOfUserPurchasedModels
                // case url.endsWith('/models'):
                //     return fakeGetListOfUserPurchasedModels();
                // case url.endsWith('/validate_purchase') && method === 'POST':
                //   return fakePostRequestPurchaseIntent();
                // case url.endsWith('/confirm_purchase') && method === 'POST':
                //   return fakePostRequestConfirmPurchase();
                // case url.endsWith('/reviews') && method === 'POST':
                //   return getResponseSenToReview();
                // case url.endsWith('/getModelById') && method === 'GET':
                //   return getResponseModelById();
                // case url.endsWith('/activate_trial') && method === 'POST':
                //   return postActivateTrial();
                // case url.endsWith('/subscription_status') && method === 'POST':
                //   return fakeGetUserSubscriptionStatus();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }

        }

        // route functions

        function authenticate() {
            const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) return error('Username or password is incorrect');
            return ok({
                id: user.id,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                token: 'fake-jwt-token'
            })
        }

        // UNCOMMENT TO USE FAKE BACKEND !!!!!!!!!!!!!!!!!!!

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        function fakeGetListOfUserPurchasedModels() {
          const models = getRecentUserData();
         const resp = {
           'model_ids': models,
            'error': '',
            'error_details': '',
        }
          return ok(resp)
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        function fakeGetUserSubscriptionStatus() {
          const models = getRecentUserData();
          const resp: UserSubscriptionStatusDto = {
            'user_status': SUBSCRIPTION_STATUS.SUBSCRIBER,
            'uploads_count' : 0,
            'uploads_max_count': 100,
            'billing_period_end': '2024-10-25T09:54:50Z',
            'autorenew_is_disabled': null,

        }
          return ok(resp)
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        function fakePostRequestPurchaseIntent() {
          // {model_id:"bd0ed1409322435fa7709e2c43f9dad2"user_id:"HEwUxd4Llhha9axvsP9cNkGAfHg2"}
          const payload = body
          return ok({model_available: true, error: '', product_id: '3DWayDev1_26', error_details: ''})
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        function fakePostRequestConfirmPurchase() {
          // {model_id:"bd0ed1409322435fa7709e2c43f9dad2"user_id:"HEwUxd4Llhha9axvsP9cNkGAfHg2"}
          const payload = body
          console.log('CONFIRM PURCH !!!', payload)
          addRecentPurchase(payload.model_ids[0])
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          payload.model_ids[0]
          return ok({status: 'confirmed', error: '', product_id: '3DWayDev1_26', error_details: ''})
        }

        function getUsers() {
            if (!isLoggedIn()) return unauthorized();
            return ok(users);
        }

        // helper functions

        // eslint-disable-next-line @typescript-eslint/no-shadow
        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }))
        }

        function error(message) {
            return throwError(() => error);
            // return throwError({ error: { message } });
        }

        function unauthorized() {
          return throwError(() => 'error');
            // return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === 'Bearer fake-jwt-token';
        }
    }

    public fd(): void {

    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
