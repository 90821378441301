import { WebNavService } from './../webnavigation.service';
import { ICardCollection } from 'src/app/models/collection';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
/* eslint-disable prefer-const */


import { CARDS_MODE, CARD_CONTROL_BTN, CARD_PURCHASE_STATUS, CARD_TYPE, ICard, ICardAction, ICardFeedModificator, INavpanelAction, ModelCardRendered, NAVPANEL_ACTION_TYPE, NoDataMessage, PRELOAD_DIRECTION } from 'src/app/models/card';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy, Inject, AfterViewInit, ElementRef, ViewChild, ViewChildren, isDevMode, ChangeDetectorRef, QueryList, ChangeDetectionStrategy, DoCheck } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { Observable, Subscription, debounceTime, distinctUntilChanged, merge, of, skip, startWith, switchMap, take, tap, throttleTime } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll-core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { IntersectionStatus } from 'src/app/shared/directives/intersection-observer';
import { cardAnimation, fadeWithDelay } from 'src/app/shared/providers/animations-list';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { PURCHASE_PROCESS } from 'src/app/shared/providers/firebase-auth.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';

const BASE_VIDEO_LENGTH = 15;
const BASE_VIDEO_LOADED_LENGTH = 12;
const CORRECT_APP_POSTER = environment.type === 'web' ? 'cardPosterWeb' : 'cardPosterMobile';
const CORRECT_APP_CONTENT = environment.type === 'web' ? 'cardContentWeb' : 'cardContentMobile';



// FOR DEBUG;
// window.document.querySelectorAll('video').length;
// window.document.querySelectorAll('.card-img').length;

@Component({
  selector: 'app-web-models-card',
  templateUrl: 'web-models-card.component.html',
  styleUrls: ['./web-models-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)', opacity: 1 })),
      // transition('void => *', [
      //   style({ transform: 'translateX(-120%)', opacity: 0 }),
      //   animate('0.3s ease-out')
      // ]),
      transition('* => void', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]),
    cardAnimation,
    fadeWithDelay
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlapTrigger: false,
        yPosition: 'above',
        backdropClass: 'webmenu-backdrop',
        overlayPanelClass: 'webmenu-overlay'
      }
    }
  ]
})
export class WebModelsCardComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  @ViewChild('cardsWrapperEl')
  public cardsWrapper: ElementRef;

  @ViewChildren('cardItemRef')
  public cardViewChildren: QueryList<ElementRef>;
  authPopUpCloseSubscription: Subscription;


  @Input() set cardsRaw(value: ModelCardRendered[]) {
    this.cardsSource = JSON.parse(JSON.stringify(value));
    this.cards = JSON.parse(JSON.stringify(value));

    if(this.startPosition) {
      const halfOfBuffer = Math.round(this.settingService.cardRenderBuffer / 2);
      const indexToShowHigh = this.startPosition + halfOfBuffer;
      const indexToShowLow = this.startPosition - halfOfBuffer;

      const posterIndexBackward = this.startPosition - this.settingService.posterBufferBackward;
      const posterIndexForward = this.startPosition + this.settingService.posterBufferForward;


      this.cards.forEach((card, index) => {
        if(index >= indexToShowLow && index <= indexToShowHigh) card.isVideoShown = true;
        if(index >= posterIndexBackward && index <= posterIndexForward) card.isPosterShown = true;
      })
      this.cards.length = this.startPosition + this.settingService.skeletonChunkLength < this.cards.length ? this.startPosition + this.settingService.skeletonChunkLength : this.cards.length;
    } else {
      this.cards.forEach((card, index) => {
        if(index < this.cardBuffer) card.isVideoShown = true;
        if(index < this.settingService.posterBufferForward) card.isPosterShown = true;
      })
      if(this.settingService.skeletonChunkLength === 1 && this.cards.length > this.settingService.skeletonChunkLength) this.cards.length = (this.settingService.skeletonChunkLength + 1)
      if(this.settingService.skeletonChunkLength < this.cards.length) this.cards.length = this.settingService.skeletonChunkLength;
    }
  }

  @Input() feedIsVisible: boolean;
  @Input() feedMode: CARDS_MODE = CARDS_MODE.HOME_COLLECTION_FEED;
  @Input() feedModificator: ICardFeedModificator;
  @Input() startPosition: number;
  @Input() isLikeHidden: boolean = false;
  private startPositionCardNotShown: boolean = true;
  @Input() noDataMessage: NoDataMessage = {text:'No cards data'};

  @Output() cardClicked = new EventEmitter<ICard>();
  @Output() cardPressed = new EventEmitter<ICard>();
  @Output() cardVisibilityChanged = new EventEmitter<ModelCardRendered>();
  @Output() actionBtnClicked = new EventEmitter<ICardAction>();

  CORRECT_APP_CONTENT = CORRECT_APP_CONTENT;
  CORRECT_APP_POSTER = CORRECT_APP_POSTER;

  public modelLikeClick: EventEmitter<ICard> = new EventEmitter<ICard>();
  public collectionLikeClick: EventEmitter<ICardCollection> = new EventEmitter<ICardCollection>();



  environment = environment

  public CARDS_MODE = CARDS_MODE;
  public CARDS_PURCHASE_STATUS = CARD_PURCHASE_STATUS;

  public cards: ModelCardRendered[];
  public cardsSource: ModelCardRendered[];

  public cardOptionsItems: INavpanelAction[] = [];

  private pageScrollInstance: PageScrollInstance;
  public cardActivationSubscription: Subscription;
  public cardChangedSubscription: Subscription;
  public cardPurchaseProcessSubscription: Subscription;

  // public calculatedRootMargin: string = '-10px 0px -90px 0px';
  public calculatedRootMargin: string = '-10px 0px -10px 0px';

  public cardBuffer: number;

  public isWebLayout: boolean = environment.type === 'web';


  public isCardInPurchaseProcess: boolean = false;
  public isChunkSpinnerVisible: boolean = false;
  private addingSkeletonChunkInProgres: boolean = false;
  private preloadDirection: PRELOAD_DIRECTION = PRELOAD_DIRECTION.FORWARD;

  private firstPerfMark: number;

  private lastLikedBtnElementTarget: HTMLButtonElement;
  private lastSnackBarRef: MatSnackBarRef<TextOnlySnackBar>

  private lastLikeActionType: CARD_TYPE;

  constructor(public cardService: CardsService,
              public userDataService: UserDataService,
              private router: Router,
              private pageScrollService: PageScrollService,
              private cdr: ChangeDetectorRef,
              public settingService: SettingsService,
              private _snackBar: MatSnackBar,
              public webNavService: WebNavService,
              public utilityFunctionService: UtilityFunctionsService,
              @Inject(DOCUMENT) private document: any
              ) {
                  this.cardBuffer = this.settingService.cardRenderBuffer;

                  this.modelLikeClick.pipe(
                    throttleTime(500, undefined, { leading: true, trailing: false }),
                    switchMap((card) => this.handleModelLikeClick(card))
                  ).subscribe((response) => {
                  });

                  this.collectionLikeClick.pipe(
                    throttleTime(500, undefined, { leading: true, trailing: false }),
                    switchMap((col) => this.handleCollectionLikeClick(col))
                  ).subscribe((response) => { });

                }

  ngOnInit() {

    // After settings we need to be sure, that data in default;
    this.cardService.resetCardService();

    this.firstPerfMark = performance.now()
    console.log(`[DEBUG!!!!!!!!] start setting current card: ${this.firstPerfMark}`)
    // TODO: Refactor here async
    if(this.feedIsVisible && this.cards[0]) {
      if(this.startPosition && this.cards[this.startPosition]) this.cardService.setCurrentCard(this.cards[this.startPosition]);
      else this.cardService.setCurrentCard(this.cards[0]);
    }

    this.cardActivationSubscription = this.cardService.onApplicationActivationSubject.subscribe((currentActiveCard: ICard) => {
      if(this.feedIsVisible) this.handleAppActivation(currentActiveCard);
    });

    this.cardChangedSubscription = this.cardService.onCardChangedInVisibleZone.subscribe((changedCards: ICard[]) => {
      if(this.feedIsVisible) this.handleCardChange(changedCards);
    });

    this.cardPurchaseProcessSubscription = this.cardService.onCardPurchaseStateChange.subscribe((purchaseState: PURCHASE_PROCESS) => {
      if(this.feedIsVisible) this.handlePurchaseStateChange(purchaseState);
    });

    this.authPopUpCloseSubscription = this.webNavService.webAuthPopupClosed.subscribe((event) => {
      if(this.lastLikeActionType) {
              switch (this.lastLikeActionType) {
                case CARD_TYPE.MODEL:
                  this.userDataService.removeFromSavedCards(this.cardService.getCurrentCard(), false);
                  break;
                case CARD_TYPE.COLLECTION:
                  this.userDataService.removeFromSavedCollections(this.userDataService.generateCollectionFromCard(this.cardService.getCurrentCard()), false);
                  break;
                default:
                  break;
              }
              this.lastLikeActionType = undefined;
        }
    });
  }

  ngAfterViewInit(): void {
    if(this.startPosition) {
      this.setCustomStartCard();
    }

    this.pageScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollTarget: '.model-card-wrapper',
      duration: 600,
      scrollViews: [this.cardsWrapper.nativeElement],
      advancedInlineOffsetCalculation: true,
      interruptible: false
    });

    // Subscribe on DOM changes to make preload mechanism work (simply with chaning variable 'addingSkeletonChunkInProgres')
    this.cardViewChildren.changes.subscribe(
      (next: QueryList<ElementRef>) => {
        // skip on first init of the feed;
        if(next.length <= 1 + this.settingService.skeletonChunkLength) return;

        this.addingSkeletonChunkInProgres = false;
     }
    )
  }

  ngDoCheck(): void {
    // console.log('DO CHECK OCCURED')
    this.recalculateFooterShift();
    this.recalculateFooterShiftForCentralPosition();
    this.calculateDescriptionHeight();
  }

  private handlePurchaseStateChange(purchaseState: PURCHASE_PROCESS): void {
    switch (purchaseState) {
      case PURCHASE_PROCESS.DEFAULT:
        console.log('PURCHASE_PROCESS.DEFAULT')
        this.isCardInPurchaseProcess = false;
        this.cdr.detectChanges();
        break;
      case PURCHASE_PROCESS.ERROR:
        console.log('PURCHASE_PROCESS.ERROR')
        this.isCardInPurchaseProcess = false;
        this.cdr.detectChanges();
        break;
      case PURCHASE_PROCESS.PROCESSING:
        console.log('PURCHASE_PROCESS.PROCESSING');
        this.isCardInPurchaseProcess = true;
        this.cdr.detectChanges();
        break;
      case PURCHASE_PROCESS.SUCCESS:
        console.log('PURCHASE_PROCESS.SUCCESS');

        this.isCardInPurchaseProcess = false;
        this.cdr.detectChanges();
        break;
      default:
        break;
    }
  }

  private handleAppActivation(currentActiveCard: ICard): void {
        // Check, if this model-card-feed is now really active;
        const activeCard = this.cards.find((card => {
          return card?.cardTitle === currentActiveCard?.cardTitle && card?.[CORRECT_APP_CONTENT] === currentActiveCard?.[CORRECT_APP_CONTENT]
        }));

        if(activeCard) {
          const activeVideoElement = this.findVideoElementInDom(activeCard[CORRECT_APP_CONTENT]);
          this.startPlaying(activeVideoElement);
        }
  }

  private handleCardChange(changedCards: ICard[]): void {
      // if(changedCards[0] === undefined) return;

      // TODO: Refactor here async
      const previousCard = this.cards.find((card => {
        return card.cardTitle === changedCards[0]?.cardTitle && card[CORRECT_APP_CONTENT] === changedCards[0]?.[CORRECT_APP_CONTENT]
      }));

      const nextCard = this.cards.find((card => {
        return card.cardTitle === changedCards[1]?.cardTitle && card[CORRECT_APP_CONTENT] === changedCards[1]?.[CORRECT_APP_CONTENT]
      }));

      nextCard.isVideoShown = true;
      window.setTimeout(()=>{
        console.log('VIDEO LENGTH: ',window.document.querySelectorAll('video').length, ' HANDLECARDCHANGE');
      },50)

      nextCard.isPosterShown = true;

      if(previousCard && nextCard) {
        const prevCardIndex = this.findCardIndex(previousCard);
        const nextCardIndex = this.findCardIndex(nextCard);
        this.preloadDirection = nextCardIndex > prevCardIndex ? PRELOAD_DIRECTION.FORWARD : PRELOAD_DIRECTION.BACKWARD;
        this.cardService.setPreloadDirection(this.preloadDirection);
        if(nextCardIndex >= (this.cards.length - (this.settingService.skeletonChunkLength - 1) ) && this.addingSkeletonChunkInProgres === false) {
          const lastVideoPosition = this.cards.length - this.cards.reverse().findIndex((el)=>{return el.isVideoShown === true});
          const lastImgPosition = this.cards.length - this.cards.reverse().findIndex((el)=>{return el.isPosterShown === true});
          console.log('VIDEO LENGTH, VID, POST:',lastVideoPosition, lastImgPosition);
          this.addSkeletonChunk();
        }
      }

      console.log('**NEXT_CARD_TO_START_PLAY:', nextCard.cardTitle);

      // Query selecting nodes here;
      const feedVideosConverted: HTMLVideoElement[] = Array.prototype.slice.call(this.cardsWrapper.nativeElement.querySelectorAll('video'));
      const previousCardVideoElement: HTMLVideoElement = feedVideosConverted.find(node => node.currentSrc.substring(node.currentSrc.indexOf('/assets/'), node.currentSrc.length) === previousCard?.[CORRECT_APP_CONTENT]);
      const nextCardVideoElement: HTMLVideoElement = feedVideosConverted.find(node => node.currentSrc.substring(node.currentSrc.indexOf('/assets/'), node.currentSrc.length) === nextCard?.[CORRECT_APP_CONTENT]);
      const indexPrev = this.findCardIndex(changedCards[0]);
      const indexNext = this.findCardIndex(changedCards[1]);

      /* REFACTORING PART HERE: */
      this.resetCardLoad(previousCardVideoElement, previousCard);
      // To be sure, that the video will 'download' immediately, not waiting for card to be scrolled fully down;
      this.startPlaying(nextCardVideoElement, 10);
      // To give some time angular to add new video nodes and to set proper preload mechanism;
      window.setTimeout(()=>{
        let feedVideosConvertedTimeout: HTMLVideoElement[] = Array.prototype.slice.call(this.cardsWrapper.nativeElement.querySelectorAll('video'));
        let nextCardVideoElementTimeout: HTMLVideoElement = feedVideosConverted.find(node => node.currentSrc.substring(node.currentSrc.indexOf('/assets/'), node.currentSrc.length) === nextCard?.[CORRECT_APP_CONTENT]);
        this.setCardsToPreload(feedVideosConvertedTimeout, nextCardVideoElementTimeout );
      }, 100)
      /* ********************* */

      // Scrolling down
      if((indexPrev || indexPrev === 0) && indexNext && indexNext > indexPrev  ) {
        let nextCardToAddVideoTagIndex = indexNext + this.cardBuffer - 1;

        // this.disposeOldVideo((indexNext - (this.settingService.cardRenderBufferBackward+1)), '**VIDEO_DISPOSE_DOWN(');
        // this.disposeOldPoster((indexNext - (this.settingService.posterBufferBackward+1)), '**POSTER_DISPOSE_DOWN(');
        this.advancedScrollContentDisposer(indexNext, PRELOAD_DIRECTION.FORWARD);
        this.addVideoToDom(nextCardToAddVideoTagIndex);
        this.addPostersToDom(nextCardToAddVideoTagIndex, PRELOAD_DIRECTION.FORWARD);

      }

      // Scrolling up
      if((indexNext || indexNext === 0) && indexPrev && indexPrev > indexNext) {
        let nextCardToAddVideoTagIndex = indexPrev - this.cardBuffer; // ??? - 1 ?

        // this.disposeOldVideo((indexPrev + this.settingService.cardRenderBufferBackward), '**VIDEO_DISPOSE_UP(');
        // this.disposeOldPoster((indexNext + this.settingService.posterBufferBackward), '**POSTER_DISPOSE_UP(');
        this.advancedScrollContentDisposer(indexPrev, PRELOAD_DIRECTION.BACKWARD);
        this.addVideoToDom(nextCardToAddVideoTagIndex);
        this.addPostersToDom(nextCardToAddVideoTagIndex, PRELOAD_DIRECTION.BACKWARD);

      }
  }

  public disposeAllVideosExceptCurrent(): void {
    const current = this.cardService.getCurrentCard() ;
    const currentInd = this.cards.findIndex((el)=>{return el[CORRECT_APP_CONTENT] === current[CORRECT_APP_CONTENT]});
    this.cards.forEach((card, index) => {
      if(index === currentInd) return;
      card.isVideoShown = false
    })
  }

  private resetCardLoad(node: HTMLVideoElement, referenceCard: ModelCardRendered ): void {
        if(node === undefined) return;

        let bufferedTime = 0;
        if(node.buffered && node.buffered.length === 1) {
          bufferedTime = node.buffered.end(0);
        }
        console.warn('** PREV VIDEO INSIDE: ', referenceCard.cardTitle , ' **', 'BUF LENGTH: ', node.buffered.length, 'BUF END: ', bufferedTime);
        if(isNaN(node.duration) || node.buffered.length === 0 || node.buffered.length === 1 && node.buffered.end(0) < BASE_VIDEO_LOADED_LENGTH) {
          // HIDE to stop video load;
          window.setTimeout(() => {
            const nodesrcOriginal = node.src;
            console.warn('** VIDEO TO HIDE: ', referenceCard.cardTitle , ' **');
            node.src = ''
            node.load();
            node.src = nodesrcOriginal

            referenceCard.isVideoShown = false;
          },250);
        }
        const isVideoFullyLoaded: boolean = node.buffered.length > 0 && node.buffered.end(0) === node.duration;
        if(isVideoFullyLoaded) {
          window.setTimeout(() => {
            node.pause();
            node.currentTime = 0;
          },450);
        }
  }

  private setCardsToPreload(feedVideos: HTMLVideoElement[], currentVideoElement: HTMLVideoElement): void {
    if(currentVideoElement === undefined) return;
    // console.log('  this.cardService.preloadNextCards(cardsToPreload, node);')
    const index = feedVideos.findIndex(video => video.currentSrc === currentVideoElement.currentSrc);
    const nextVideoIndex =  this.preloadDirection === PRELOAD_DIRECTION.FORWARD ? index + 1 : index - 1;
    const nextVideo = feedVideos[nextVideoIndex];
    if(nextVideo && nextVideoIndex >= 0 && nextVideoIndex < feedVideos.length) {
      const convertedNodes: HTMLVideoElement[] = Array.prototype.slice.call(feedVideos);
      let cardsToPreload: HTMLVideoElement[];

      if( this.preloadDirection === PRELOAD_DIRECTION.FORWARD ) {
        cardsToPreload = convertedNodes.splice(nextVideoIndex, convertedNodes.length - 1);
        this.cardService.preloadNextCards(cardsToPreload, currentVideoElement);
      }

      if ( this.preloadDirection === PRELOAD_DIRECTION.BACKWARD ) {
        cardsToPreload = convertedNodes.splice(0, nextVideoIndex + 1);
        cardsToPreload.reverse();
        this.cardService.preloadNextCards(cardsToPreload, currentVideoElement);
      }
    }
  }

  private addVideoToDom(cardIndex: number): void {
    if(this.cards[cardIndex]) {
      let nextCardVideoUrl = this.cardsSource[cardIndex][CORRECT_APP_CONTENT]
      let lastPart = nextCardVideoUrl.substring(nextCardVideoUrl.indexOf('/preview/'), nextCardVideoUrl.length);
      console.log('**CARD_ADDED_TO_DOM: ', lastPart, '( ', this.cardsSource[cardIndex].cardTitle, ' )' );
      this.cards[cardIndex].isVideoShown = true;
      this.cards[cardIndex].isPosterShown = true;
      window.setTimeout(()=>{
        console.log('VIDEO LENGTH: ',window.document.querySelectorAll('video').length, ' ADD VIDEO TO DOM');
      },50)
    }
  }

  private addPostersToDom(cardIndex: number, direction: PRELOAD_DIRECTION): void {
    switch (direction) {
      case PRELOAD_DIRECTION.FORWARD:
        const endOfPreloadForward = (this.cards.length - 1) >= cardIndex + this.settingService.posterBufferForward ? cardIndex + this.settingService.posterBufferForward : (this.cards.length - 1);
        for (let index = cardIndex; index <= endOfPreloadForward; index++) {
          try {
          this.cards[index].isPosterShown = true;

          } catch (error) {
            console.error(error)
            console.log(this.cards.length, index)
          }
        }
        break;
      case PRELOAD_DIRECTION.BACKWARD:
        const endOfPreloadBackward = 0 <= cardIndex - this.settingService.posterBufferForward ? cardIndex - this.settingService.posterBufferForward : 0;
        for (let index = cardIndex; index >= endOfPreloadBackward; index--) {
          try {
            this.cards[index].isPosterShown = true;

            } catch (error) {
              console.error(error)
              console.log(this.cards.length, index)
            }
        }
        break;
      default:
        console.error('ADD POSTER TO DOM FUNC WITHOUT DIRECTION')
        break;
    }

  }

  /* USED WHEN USER REALLY FAST SCROLL TO BE SURE, THAT ALL CARD WILL BE DISPOSED */
  private advancedScrollContentDisposer(baseDisposeIndex: number, direction: PRELOAD_DIRECTION, disposeBuffer: number = this.settingService.skeletonChunkLength*2): void {
    // this.advancedScrollContentDisposer((indexNext - this.cardBuffer), PRELOAD_DIRECTION.FORWARD);
    if(baseDisposeIndex === undefined || baseDisposeIndex < 0) {
      console.warn('BASE DISPOSE INDEX ILLEGAL!!!')
      return;
    }

    switch (direction) {
      case PRELOAD_DIRECTION.FORWARD:
      const baseDisposeIndexForwardVideo = this.cards[baseDisposeIndex - this.settingService.cardRenderBufferBackward] ? baseDisposeIndex - this.settingService.cardRenderBufferBackward : 0;
      const baseDisposeIndexForwardPoster = this.cards[baseDisposeIndex - this.settingService.posterBufferBackward] ? baseDisposeIndex - this.settingService.posterBufferBackward : 0;
      const baseDisposeIndexForward = baseDisposeIndexForwardVideo >= baseDisposeIndexForwardPoster ? baseDisposeIndexForwardVideo : baseDisposeIndexForwardPoster;
      const lastCardToCheckForward = baseDisposeIndex - disposeBuffer > 0 ? baseDisposeIndex - disposeBuffer : 0;
          for (let index = baseDisposeIndexForward; index >= lastCardToCheckForward ; index--) {
            if(index < baseDisposeIndexForwardVideo && this.cards[index].isVideoShown === true) {
              this.cards[index].isVideoShown = false;
              this.disposeLog(this.cards[index], '**DISPOSE VIDEO FORWARD: ')
            }
            if(index < baseDisposeIndexForwardPoster && this.cards[index].isPosterShown === true) {
              this.cards[index].isPosterShown = false;
              this.disposeLog(this.cards[index], '**DISPOSE POSTER FORWARD: ')
          }

            // if(this.cards[index] && this.cards[index].isVideoShown) {
            //   this.cards[index].isVideoShown = false;
            //   let disposeCardVideoUrl = this.cards[index][CORRECT_APP_CONTENT]
            //   let disposeCardLastPart = disposeCardVideoUrl.substring(disposeCardVideoUrl.indexOf('/preview/'), disposeCardVideoUrl.length);
            //   console.log('**DISPOSE_DOWN(',index ,') ADDITIONAL : ', disposeCardLastPart, '( ', this.cards[index].cardTitle, ' )' );
            // }
          }
        break;
      case PRELOAD_DIRECTION.BACKWARD:
        const baseDisposeIndexBackwardVideo = baseDisposeIndex + this.settingService.cardRenderBufferBackward;
        const baseDisposeIndexBackwardPoster = baseDisposeIndex + this.settingService.posterBufferBackward;
        const baseDisposeIndexBackward = baseDisposeIndexBackwardVideo <= baseDisposeIndexBackwardPoster ? baseDisposeIndexBackwardVideo : baseDisposeIndexBackwardPoster;
        const lastCardToCheckBackward = baseDisposeIndex + disposeBuffer >= (this.cards.length - 1) ? this.cards.length - 1 : baseDisposeIndex + disposeBuffer;
          for (let index = baseDisposeIndexBackward; index <= lastCardToCheckBackward ; index++) {
            if(index >= baseDisposeIndexBackwardVideo && this.cards[index].isVideoShown === true) {
              this.cards[index].isVideoShown = false;
              this.disposeLog(this.cards[index], '**DISPOSE VIDEO BACKWARD: ');
          }
            if(index >= baseDisposeIndexBackwardPoster && this.cards[index].isPosterShown === true) {
              this.cards[index].isPosterShown = false;
              this.disposeLog(this.cards[index], '**DISPOSE POSTER BACKWARD: ');
          }
            // if(this.cards[index] && this.cards[index].isVideoShown) {
            //   this.cards[index].isVideoShown = false;
            //   let disposeCardVideoUrl = this.cards[index][CORRECT_APP_CONTENT]
            //   let disposeCardLastPart = disposeCardVideoUrl.substring(disposeCardVideoUrl.indexOf('/preview/'), disposeCardVideoUrl.length);
            //   console.log('**DISPOSE_UP(',index ,') ADDITIONAL : ', disposeCardLastPart, '( ', this.cards[index].cardTitle, ' )' );
            // }
          }
      break;
      default:
        break;
    }
  }

  private disposeLog(card: ModelCardRendered, messageBase: string) {
      let disposeCardVideoUrl = card[CORRECT_APP_CONTENT]
      let disposeCardLastPart = disposeCardVideoUrl.substring(disposeCardVideoUrl.indexOf('/preview/'), disposeCardVideoUrl.length);
      console.log(messageBase, 'TITLE: ',card.cardTitle ,' URL: ', disposeCardLastPart);
  }

  // This function calls each time, when card is changed;
  public  onVisibilityChanged(modelCard: ModelCardRendered, status: IntersectionStatus) {
    // console.log(`[DEBUG!!!!!!!!]  VISIBILITY ${performance.now()-this.firstPerfMark} ${status} ${modelCard?.cardTitle}`);
    if(this.startPosition && this.startPositionCardNotShown) {
      this.cardService.modelFeedInitEnd = performance.now();
      console.log('MODEL FEED INIT TIME: ', this.cardService.modelFeedInitEnd - this.cardService.modelFeedInitStart);
      }

    if(this.feedIsVisible && modelCard !== undefined && status === IntersectionStatus.Visible) {
          if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();
          console.log(status,' ', modelCard.cardTitle);

          const visibleCards: ModelCardRendered[] = this.cards.filter(x => x.visible === true);
          if(visibleCards.length > 0) {
            visibleCards.forEach(x => x.cardShortView = false);
            this.cardVisibilityChanged.emit(modelCard);
            this.cdr.detectChanges();
            window.document.documentElement.classList.remove('disable-gradient-animation');
          }

          this.cards.forEach(x => x.visible = undefined);
          const currentCard = this.cards.find(x => x[CORRECT_APP_CONTENT] === modelCard[CORRECT_APP_CONTENT]);
          this.handleVisiblityForCard(currentCard);
          this.cardService.setCurrentCard(currentCard);
          this.delayedPlayStart(currentCard);
          this.autoStartCountdownTimer();
    }
  }

  private handleVisiblityForCard(currentCard: ModelCardRendered): void {
    if(this.startPosition && this.startPositionCardNotShown) {
      const activeVideoElement = this.findVideoElementInDom(currentCard?.[CORRECT_APP_CONTENT]);
      this.startPlaying(activeVideoElement, 50);
      setTimeout(() => {
        currentCard.visible = true;
        this.startPositionCardNotShown = false;
        console.log(`[DEBUG!!!!!!!!]  card set: ${performance.now()-this.firstPerfMark}`)

      }, 300);
    } else {
      currentCard.visible = true;
      console.log(`[DEBUG!!!!!!!!]  card set: ${performance.now()-this.firstPerfMark}`)

    }
  }

  private addSkeletonChunk(customSkeletonLength?: number): void {
    const skeletonChunkLength = customSkeletonLength ? customSkeletonLength : this.settingService.skeletonChunkLength
    this.addingSkeletonChunkInProgres = true;
    const sourceNewChunkStartIndex = this.cards.length;

    // return if current feed equals source feed;
    if(sourceNewChunkStartIndex >= this.cardsSource.length) return;

    const sourceNewChunkEndIndex = sourceNewChunkStartIndex + skeletonChunkLength <= (this.cardsSource.length ) ? sourceNewChunkStartIndex + skeletonChunkLength : this.cardsSource.length;

    const chunkToAdd = this.cardsSource.slice(sourceNewChunkStartIndex, sourceNewChunkEndIndex);
    if(customSkeletonLength && customSkeletonLength > this.cardBuffer) {
      chunkToAdd.forEach((card, index) => {
        const relativeIndex = index + this.cards.length;
        if(relativeIndex === customSkeletonLength ) card.isVideoShown = true;
      })
    } else {
      chunkToAdd.forEach((card, index) => {
        // if(index <= (this.cardBuffer - 1)) card.isVideoShown = true;
        // if(index <= this.settingService.posterBufferForward) card.isPosterShown = true;
      });
      window.setTimeout(()=>{
        console.log('VIDEO LENGTH: ',window.document.querySelectorAll('video').length, ' SKELETON CHUNK');
      },50)
    };

    console.log('VIDEO LENGTH CHUNK TO ADD: ', chunkToAdd.filter((el)=>{return el.isVideoShown === true}).length )

    this.cards = this.cards.concat(chunkToAdd);
    console.log('VIDEO LENGTH CARDS: ', this.cards.filter((el)=>{return el.isVideoShown === true}).length )

    console.log('*** SKELETON ADD FINISHED *** NEW LENGTH: ', this.cards.length);
  }

  private findCardIndex(currentCard: ICard): number {
    if(currentCard?.[CORRECT_APP_CONTENT]) {
      const inx = this.cardsSource.findIndex((card, index) => {
        return card[CORRECT_APP_CONTENT] === currentCard[CORRECT_APP_CONTENT]
      })
      return inx
    }
  }

  public setFirstCardExplicitVisible(): void {
    this.cards[0].visible = true;
  }

  public setActiveComponentCardToCardService(): void {
    const visibleCards: ModelCardRendered[] = this.cards.filter(x => x.visible === true);
    if(visibleCards.length > 0) {
      this.cardService.setCurrentCard(visibleCards[0]);
    }
  }
  public ngOnDestroy(): void {
    const subscriptions = [
      this.cardActivationSubscription,
      this.cardChangedSubscription,
      this.cardPurchaseProcessSubscription,
      this.authPopUpCloseSubscription
    ];
    subscriptions.forEach(sub => sub?.unsubscribe());
  }

  getNoDataText(): string {
    // STRANGE MOMENT WITH SAVED CARDS HERE; Investigate in future;
    return this.noDataMessage ? this.noDataMessage.text : 'Fetching card feed...'
  }

  public onCardClicked(card: ModelCardRendered, event: any) {
    const eventSrcName = event.srcEvent.srcElement.localName;
    const isFooterRow = event.srcEvent.srcElement.classList.contains('footer-row');
    if(eventSrcName === 'svg' || eventSrcName === 'span' || eventSrcName === 'button' || eventSrcName === 'path' || eventSrcName === 'circle' || eventSrcName === 'mat-icon' || isFooterRow) return;
      this.utilityFunctionService.dissmissAllSnackBars();

      if(card.cardShortView) {
      // HACK TO AVOID APPLYING STANDART CSS ANIMATION WITH DELAY;
      setTimeout(() => {
        window.document.documentElement.classList.remove('disable-gradient-animation');
        }, 15);
      } else {
        // HACK TO AVOID APPLYING STANDART CSS ANIMATION WITH DELAY;
        window.document.documentElement.classList.add('disable-gradient-animation');
      }

    card.cardShortView = !card.cardShortView;

    const eventTarget: any = event.currentTarget || event.target;
    const videoTag: HTMLVideoElement = eventTarget.closest('.card-item').querySelector('video');
    videoTag?.play();
    if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();
    this.cardClicked.emit(card);
    return
    // card.isFullscreen = true;
    // this.cardClicked.emit(card);
  }

  public onCardPress(card: ModelCardRendered, event: any) {
    const target = event.target;
    if (target instanceof Element) {
      const eventSrcName = target.localName;
      const isFooterRow = event.srcEvent.srcElement.classList.contains('footer-row');

      if(eventSrcName === 'svg' || eventSrcName === 'span' || eventSrcName === 'button' || eventSrcName === 'path' || eventSrcName === 'circle' || eventSrcName === 'mat-icon' || isFooterRow) return;

      const videoTag: HTMLVideoElement = target.closest('.card-item').querySelector('video');
      videoTag?.pause();
      console.log('press');

      window.document.documentElement.classList.add('disable-gradient-animation');
      card.cardShortView = true;
      if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();
      this.cardPressed.emit(card);
    }

  }

  public onCardRelease(card, event) {
    const eventSrcName = event.srcEvent.srcElement.localName;
    const isFooterRow = event.srcEvent.srcElement.classList.contains('footer-row');
    if(eventSrcName === 'svg' || eventSrcName === 'span' || eventSrcName === 'button' || eventSrcName === 'path' || eventSrcName === 'circle' || eventSrcName === 'mat-icon' || isFooterRow) return;

    const eventTarget: any = event.currentTarget || event.target;
    const videoTag: HTMLVideoElement = eventTarget.closest('.card-item').querySelector('video');
    videoTag?.play();
    console.log('release');
  }

  public voidBtn(event: Event): void {
    event.stopPropagation();
  }

  public voidBtnNotImp(event: Event): void {
    event.stopPropagation();
    this.lastSnackBarRef = this.showSnackBar('Not implemented', 750)
  }

  public buyBtnClicked(event: Event, card: ModelCardRendered): void {
    console.log('buybtn')
    const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.BUY_MODEL}
    this.actionBtnClicked.emit(action)
    event.stopPropagation();
  }

  private setCustomStartCard(): void {
    this.cardsWrapper.nativeElement.scrollTo({
      top: window.innerHeight * this.startPosition,
      left: 0
    });
    console.log('HEIGHT: ', window.innerHeight)
  }

  public onGoToDetailsButtonClicked(event: Event, card: ICard) {
    this.utilityFunctionService.dissmissAllSnackBars();
    const currentPlayVideoElement = this.findVideoElementInDom(card[CORRECT_APP_CONTENT]);
    currentPlayVideoElement?.pause();
    if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();

    event.stopPropagation();
    console.log('Go to details clicked');
    const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.CONTROL_GO_TO_DETAILS};
    this.actionBtnClicked.emit(action)
  }

  public dispatchCurrentCardLikeClick() {
    const currentCard = this.cardService.getCurrentCard();
    const videoElement = this.findVideoElementInDom(currentCard[CORRECT_APP_CONTENT]);

    videoElement.parentElement.parentElement.getElementsByClassName('like-web-btn')[0].dispatchEvent(new Event('click'));
  }

  public isShareBtnVisible(): boolean {
    // eslint-disable-next-line max-len
    return this.feedMode === CARDS_MODE.HOME_COLLECTION_FEED || this.feedMode === CARDS_MODE.MODEL_CARD_FEED || this.feedMode === CARDS_MODE.EDIT_CARD  || this.feedMode === CARDS_MODE.COMPOSITE_FEED || this.feedMode === CARDS_MODE.COMPOSITE_FEED;
  }

  public isLikeBtnVisible(card?: ICard): boolean {
    // eslint-disable-next-line max-len
    if(this.isSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED) return true;
    return this.feedMode === CARDS_MODE.HOME_COLLECTION_FEED || this.feedMode === CARDS_MODE.MODEL_CARD_FEED;
  }

  public isOptionsBtnVisible(): boolean {
    if(this.feedModificator?.hiddenOptionsButton) return false;
    return this.feedMode === CARDS_MODE.EDIT_CARD || this.feedMode === CARDS_MODE.COMPOSITE_FEED || this.feedMode === CARDS_MODE.MODEL_CARD_FEED ;
  }

  public isDescriptionVisible(): boolean {
    return this.feedMode === CARDS_MODE.HOME_COLLECTION_FEED;
  }

  public isPurchaseBtnAllowed(cardPurchaseStatus: CARD_PURCHASE_STATUS): boolean {
    return cardPurchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY || cardPurchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED || cardPurchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED;
  }

  public isDownloadBtnVisible(card?: ICard): boolean {
    if(card) {
      return !!card.cardModelLink
      // return card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED || card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED;
    }
  }

  public noLinkClick(event: Event): void {
    event.stopPropagation();
    this.showSnackBar('No link available, please, contact support', 750);
    return
  }

  public getCardLink(card?: ICard): string {
    // if(card) {
    //   return card.cardModelLink ? card.cardModelLink : this.cardService.usdzHref;
    // }
    if(card) {
      return card.cardModelLink ? card.cardModelLink : undefined;
    }
  }

  public nativeScrollToTop(): void {
    this.cardsWrapper.nativeElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }

  public scrollToTop(): void {
    // TODO: REWRITE LATER THE CODE BELOW;
    const a = this.cardsWrapper.nativeElement.querySelectorAll('.card-item');
    const lastElement = a[a.length - 1];
    this.pageScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollTarget: lastElement,
      duration: 600,
      scrollViews: [this.cardsWrapper.nativeElement],
      advancedInlineOffsetCalculation: true,
      interruptible: false
    });

    this.pageScrollService.start(this.pageScrollInstance);

    // if(this.settingService.devicePlatform === 'ios') {
    //   // temporary disable scroll to top;
    // } else {
    //   this.cardsWrapper.nativeElement.firstElementChild.scrollIntoView({ behavior: 'smooth'});
    // }
  }

  private startPlaying(nodeVideo: HTMLVideoElement, pauseAfter?: number) {
    if(nodeVideo) {
      nodeVideo.play().catch((err)=>{
        if(this.settingService.showDetailedLogForModelCards) {
          console.warn(err);
          console.log('ERROR: ', nodeVideo.currentSrc);
        }
       });
    }
    if(pauseAfter && nodeVideo) {
      window.setTimeout(() => {
        nodeVideo.pause();
      },10);
    }
  }

  public startPlayVisibleCollectionCard(): void {
    const currentCard = this.cardService.getLastCollectionCard();
    if(currentCard) {
      const cardToStartPlay = this.findVideoElementInDom(currentCard[CORRECT_APP_CONTENT]);
      window.setTimeout(() => {
        cardToStartPlay.play();
      }, 200);
    }
  }

  private delayedPlayStart(card: ICard): void {
    window.setTimeout(() => {
      const delayedPlayVideoElement = this.findVideoElementInDom(card[CORRECT_APP_CONTENT]);
      if(delayedPlayVideoElement && this.cardService.getCurrentCard()?.[CORRECT_APP_CONTENT] === card[CORRECT_APP_CONTENT]) {
        delayedPlayVideoElement.play().catch((err)=>{
          console.log(err)
        });
      }
    }, this.settingService.cardStartDelayTime);
  }

  private autoStartCountdownTimer(): void {
      const initTiming: number = this.settingService.cardStartDelayTime;
      let timePassed: number = 0;

      const timerInterval = window.setInterval(() => {
        timePassed = timePassed + 1000;
        const autoStartElementLabel = this.cardsWrapper.nativeElement.querySelector('.autoplay-value');
        if(timePassed <= initTiming && autoStartElementLabel) {
          let timeLeftToStart = (initTiming - timePassed)/1000;
          autoStartElementLabel.innerHTML = timeLeftToStart
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
  }

  private findVideoElementInDom(videoSrc: string): HTMLVideoElement {
    const feedVideosConverted: HTMLVideoElement[] = Array.prototype.slice.call(this.cardsWrapper.nativeElement.querySelectorAll('video'));
    const currentVideo = feedVideosConverted.find(videoNode => {
      const srcString =  videoNode.currentSrc.substring(videoNode.currentSrc.indexOf('/assets/'),videoNode.currentSrc.length);
      return srcString === videoSrc
    })
    return currentVideo
  }

  public isSaved(card: ICard): boolean {
    if(card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED) return true;
    if(card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED) return false;
    if(card.cardType === CARD_TYPE.MODEL) return this.userDataService.isCardExitsInSaved(card);
    if(card.cardType === CARD_TYPE.COLLECTION) return this.userDataService.isCollectionExitsInSaved(this.userDataService.generateCollectionFromCard(card));
  }

  public onLikeButtonClicked(card: ModelCardRendered, event: Event): void {
    event.stopPropagation();
    console.log('LIKE PRESSED:', card);

    // Workaround to trigger animation only when 'like' click button was performed;
    const eventTarget: any = event.currentTarget;
    this.lastLikedBtnElementTarget = eventTarget;
    eventTarget.classList.add('touched');

    if(card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED) {
      this.lastSnackBarRef = this.showSnackBar('Removed from Your Collection.', 750)
      card.purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED;
      const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD}
      this.actionBtnClicked.emit(action)
      return
    }

    if(card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED) {
      this.lastSnackBarRef = this.showSnackBar('Added to Your Collection.', 750);
      card.purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_LIKED;
      const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.LIKE_DRAFT_CARD}
      this.actionBtnClicked.emit(action)
      return
    }

    if(card.cardType === CARD_TYPE.MODEL) {
      if(!this.webNavService.isUserAuthenticated) {
        this.actionBtnClicked.emit({card, controlAction: CARD_CONTROL_BTN.ANONYMOUS_USER});
        this.lastLikeActionType = CARD_TYPE.MODEL;
        this.userDataService.addToSavedCards(this.cardService.getCurrentCard(), false);
        return;
      }
      this.modelLikeClick.next(card);
      // this.userDataService.isCardExitsInSaved(card) ? this.userDataService.removeFromSavedCards(card) : this.userDataService.addToSavedCards(card);

      if(this.userDataService.isCardExitsInSaved(card)) {
        this.lastSnackBarRef = this.showSnackBar('Added to Library.', 750);
      } else {
        this.lastSnackBarRef = this.showSnackBar('Removed from Library.', 750)
      }

    }

    if(card.cardType === CARD_TYPE.COLLECTION) {
      if(!this.webNavService.isUserAuthenticated) {
        this.actionBtnClicked.emit({card, controlAction: CARD_CONTROL_BTN.ANONYMOUS_USER});
        this.lastLikeActionType = CARD_TYPE.COLLECTION;
        this.userDataService.addToSavedCollections(this.userDataService.generateCollectionFromCard(this.cardService.getCurrentCard()), false);
        return;
      }

      const temporaryCollection: ICardCollection = this.userDataService.generateCollectionFromCard(card);
      this.collectionLikeClick.next(temporaryCollection)
      // this.userDataService.isCollectionExitsInSaved(temporaryCollection) ? this.userDataService.removeFromSavedCollections(temporaryCollection) : this.userDataService.addToSavedCollections(temporaryCollection);

      if(this.userDataService.isCollectionExitsInSaved(temporaryCollection)) {
        this.lastSnackBarRef = this.showSnackBar('Added to Library.', 750);
      } else {
        this.lastSnackBarRef = this.showSnackBar('Removed from Library.', 750)
      }

    }
  }

  private handleModelLikeClick(card: ICard): Observable<{ action: string }> {
    if (this.userDataService.isCardExitsInSaved(card)) {
      this.userDataService.removeFromSavedCards(card);
      return of({ action: 'removed' });
    } else {
      this.userDataService.addToSavedCards(card);
      return of({ action: 'added' });
    }
  }

  private handleCollectionLikeClick(collection: ICardCollection): Observable<{ action: string }> {
    if (this.userDataService.isCollectionExitsInSaved(collection)) {
      this.userDataService.removeFromSavedCollections(collection);
      return of({ action: 'removed' });
    } else {
      this.userDataService.addToSavedCollections(collection);
      return of({ action: 'added' });
    }
  }

  private showSnackBar(text: string, duration: number = 2000): MatSnackBarRef<TextOnlySnackBar> {
    return this._snackBar.open(text, undefined, {
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      duration,
      panelClass: 'like-snackbar'
    })
  }

  public async onShareBtnClicked(card: ICard, event?: Event ): Promise<void> {
    console.log('SHARE:', card);
    if(event) event.stopPropagation();
    this.lastSnackBarRef = this.showSnackBar('Coming soon', 750);

    // this.cardService.sendItemForSharing(card);

    // if(card.cardType === CARD_TYPE.COLLECTION) {
    //   await Share.share({
    //     title: `3DWay content to share: ${card.cardTitle}`,
    //     text: `Really awesome thing you need to see ${card.cardTitle}`,
    //     url: `${environment.universalLinkBaseUrl}/home?collectionId=${card.id}`,
    //     dialogTitle: 'Sharing 3Dway Collection',
    //   });
    // }
    // if(card.cardType === CARD_TYPE.MODEL) {
    //   await Share.share({
    //     title: `3DWay content to share: ${card.cardTitle}`,
    //     text: `Really awesome thing you need to see ${card.cardTitle}`,
    //     url: `${environment.universalLinkBaseUrl}/home?modelId=${card.id}`,
    //     dialogTitle: 'Sharing 3Dway Model',
    //   });
    // }
  }

  public trackByIndex(_index: number, item: ModelCardRendered) {
    return item?.[CORRECT_APP_CONTENT];
  }

  public onVideoCanBePlayed(canBePlayed: boolean, card: ModelCardRendered): void {
    card.canBePlayed = canBePlayed;
  }

  public onSearchBtnClick(card: ModelCardRendered, event: Event): void {
    event.stopPropagation();
    if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();
    const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.SEARCH}
    this.actionBtnClicked.emit(action)
    event.stopPropagation();
    // this.lastSnackBarRef = this.showSnackBar('Search not implemented yet', 750)
    // window.setTimeout(()=>{
    //   this.router.navigateByUrl('/');
    // },this.settingService.homeBtnEventDelayTime)
  }

  public onLibraryBtnClick(event: Event): void {
    event.stopPropagation();
    if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();
    window.setTimeout(()=>{
      this.router.navigateByUrl('/');
    },this.settingService.homeBtnEventDelayTime)
  }

  public backBtnClick(event: Event): void {
    event.stopPropagation();
    if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();

    window.setTimeout(()=>{
      if(this.feedMode === CARDS_MODE.HOME_COLLECTION_FEED) {
        event.stopPropagation();
        this.router.navigateByUrl('/');
      }
      const action: ICardAction = { card: undefined, controlAction: CARD_CONTROL_BTN.CONTROL_BACK_BTN}
      this.actionBtnClicked.emit(action)
    },
    this.settingService.backBtnEventDelayTime)
  }

  public rejectedBtn(event: Event, card: ICard): void {
    const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED}
    this.actionBtnClicked.emit(action)
    event.stopPropagation();
  }

  public publishBtn(event: Event, card: ICard): void {
    this.lastSnackBarRef = this.showSnackBar('Coming soon', 750);

    // const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.PUBLISH_SINGLE_CARD}
    // this.actionBtnClicked.emit(action)
    event.stopPropagation();
  }

  public moveBtn(event: Event, card: ICard): void {
    const action: ICardAction = {card, controlAction: CARD_CONTROL_BTN.MOVE}
    this.actionBtnClicked.emit(action)
    event.stopPropagation();
  }

  public setProperCardWebPanelItems(card: ICard): void {
    this.cardOptionsItems = this.webNavService.getOptionsBtnItems(card, this.feedMode, this.feedModificator);
    console.log('GET PROPER CARD WEBPANEL ITEMS: ', card);
  }

  public downloadBtnClicked(card: ICard): void {
    this.cardService.downloadFile(this.getCardLink(card), '11.zip')

  }


  public handleWebpanelActionClick(action: INavpanelAction, card: ICard): void {
    console.log('WEBPANEL ACTION: ', action);
    const resultCardControlAction: ICardAction = this.webNavService.getCardControlActionBasedOnNavpanelItem(action, card);
    this.actionBtnClicked.emit(resultCardControlAction)
  }

  public removeLikeTouchedClass(): void {
    if(this.lastLikedBtnElementTarget) {
      this.lastLikedBtnElementTarget.classList.remove('touched');
    }
  }

public recalculateFooterShift(): number {
  const mainWebBtns = document.querySelectorAll('.card-visible .main-web-btn');
  const footMain = document.querySelector('.card-visible .foot-main');

  if (mainWebBtns.length > 0 && footMain) {
      // QUICK WORKAROUND NOT TO MAKE COMPLEX LOGIC (WHEN THERE ARE MULTIPLE MODEL FEEDS TO CALCULATE SHIFT CORRECTLY):
      const mainWebBtn = mainWebBtns.length > 1 ? mainWebBtns[1] : mainWebBtns[0];
      const { width: mainWebBtnWidth, right: mainWebBtnRight } = mainWebBtn.getBoundingClientRect();
      const { left: footMainLeft } = footMain.getBoundingClientRect();

      if (mainWebBtnWidth > 0) {
          const distance = mainWebBtnRight - footMainLeft - mainWebBtnWidth / 2;
          window.document.documentElement.style.setProperty('--mainbtn-card-shift', `${distance}px`);
          return distance;
      }
  }
  return 0; // Default return if no elements were found
}

public recalculateFooterShiftForCentralPosition(): number {
  const mainWebBtns = document.querySelectorAll('.card-visible .main-web-btn');
  const footMain = document.querySelector('.card-visible .foot-main');

  if (mainWebBtns.length > 0 && footMain) {
    const mainWebBtn = mainWebBtns.length > 1 ? mainWebBtns[1] : mainWebBtns[0];

    // Get the dimensions and positions of the elements
    const { left: mainWebBtnLeft, right: mainWebBtnRight } = mainWebBtn.getBoundingClientRect();
    const { left: footMainLeft, right: footMainRight } = footMain.getBoundingClientRect();
    const distance = mainWebBtnLeft - footMainLeft;
    const distanceToCentralPoint = (footMainRight - mainWebBtnRight) - (mainWebBtnLeft - footMainLeft);
    // console.log('footMainRight - mainWebBtnRight ', footMainRight - mainWebBtnRight );
    // console.log('mainWebBtnLeft - footMainLeft', mainWebBtnLeft - footMainLeft);

    window.document.documentElement.style.setProperty('--mainbtn-central-margin-card-shift', `${distanceToCentralPoint}px`);

    return distance;
  }

  return 0; // Default return if no elements were found
}


calculateDescriptionHeight(): number {
  const cardDescription = document.querySelector('.card-visible .card-about-short');
  const screenWidth = window.innerWidth;

  if (!cardDescription) {
    return 0; // Return 0 if the element is not found
  }

  const lineHeight = parseInt(window.getComputedStyle(cardDescription).lineHeight, 10);
  const elementHeight = cardDescription.clientHeight;
  const lines = Math.round(elementHeight / lineHeight);
  let heightShift = 380;
  if(lines === 2) {
      heightShift = 395;
      if(screenWidth < 960) {
        heightShift = 480;
      }
  } else
  if (lines > 2) {
      heightShift = 395 + (lines - 2) * 23;
      if(screenWidth < 960) {
        heightShift = 480 + (lines - 2) * 23;
      }
  }
  if(screenWidth < 960) {
    heightShift = 480;
  }
  // console.log('lines: ', lines);
  window.document.documentElement.style.setProperty('--animation-height-shift-fix', `${heightShift}px`);
  return lines;
}

onImgLoad(): void {
    // QUICK WORKAROUND TO HIDE SPLASH SCREEN WHEN FIRST CARD LOADED (KEEPING IN MIND, THAT FIRST CARD WILL TRIGGER IT);
    this.settingService.hideSplashScreen();
  }


}

