import { UtilityFunctionsService } from '../../providers/utility-functions.service';
import { ICardExtended } from './../../../models/card';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-rejected-screen',
  templateUrl: './rejected-screen.component.html',
  styleUrls: ['./rejected-screen.component.scss']
})
export class RejectedScreenComponent implements OnInit {

  @Input() rejectedCard: ICardExtended
  @Output() cancelClicked = new EventEmitter<boolean>();

  constructor(public utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
  }

  onCancelClick(): void {

    this.cancelClicked.emit()
  }


}
