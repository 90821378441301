import { SettingsService } from './../../providers/settings.service';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { INavpanelAction, NAVPANEL_ACTION_TYPE } from 'src/app/models/card';
import { UploadFileService } from '../../providers/upload-file.service';
import { UtilityFunctionsService } from '../../providers/utility-functions.service';

@Component({
  selector: 'app-navpanel',
  templateUrl: './navpanel.component.html',
  styleUrls: ['./navpanel.component.scss'],

})
export class NavpanelComponent implements OnInit, OnDestroy {
  @Input() navpanelActionItems: INavpanelAction[]

  @Output() hideNavpanelEvent = new EventEmitter<string>();
  @Output() navpanelActionClickEvent = new EventEmitter<INavpanelAction>();
  @Output() navpanelDestroyEvent = new EventEmitter<boolean>();

  NAVPANEL_ACTION_TYPE = NAVPANEL_ACTION_TYPE;


  constructor(public uploadService: UploadFileService, public settingsService: SettingsService, public utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {

  }

  onOverlayClicked(): void {
    this.hideNavpanelEvent.emit();
  }

  onCloseBtnClicked(): void {
    this.hideNavpanelEvent.emit();
  }

  onSwipe() {
    this.hideNavpanelEvent.emit();
  }

  public navpanelActionItemClick(action: INavpanelAction) {
    if(action.actionType === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL) return;
    if(action.actionType === NAVPANEL_ACTION_TYPE.ADD_VIDEO) return;
    this.navpanelActionClickEvent.emit(action);
  }

  public hideNavpanel(): void {
    this.hideNavpanelEvent.emit();
  }

  public convertToStringArray(sourceString: string): string[] {
    return sourceString.split(' ');
  }

  public closeNavpanel() {
    'closeNavpanel emitted by clicking'
    // this.navpanelActionClickEvent.emit({
    //   actionName: 'hide navpanel',
    //   actionType: NAVPANEL_ACTION_TYPE.HIDE_NAVPANEL
    // });

  }

  public async handleUploadBtnClick(event: Event, action: INavpanelAction): Promise<void> {
    event.stopPropagation();
    this.uploadService.pickAndUploadFileNativeIos(action);
    this.navpanelActionClickEvent.emit({
      actionName: 'close navpanel',
      actionType: NAVPANEL_ACTION_TYPE.CLOSE_NAVPANEL
    });
    return
  }

  public handleFileInputNavpanel(event: any, action: INavpanelAction, fileDirect?: File): void {
    const file = (event.target as HTMLInputElement)?.files?.item(0);
    if(file) {
      console.log('handling file selected navpanel');
      action.optionalData = file;
      this.uploadService.setLastFileToUploadName(this.processUSDZfileName(file.name));
      this.navpanelActionClickEvent.emit(action);
    } else {
      this.navpanelActionClickEvent.emit({
        actionName: 'close navpanel',
        actionType: NAVPANEL_ACTION_TYPE.CLOSE_NAVPANEL
      });
    }
  }

  public handleVideoInputNavpanel(event: any, action: INavpanelAction): void {
    console.log('handling video selected navpanel');

    const file = (event.target as HTMLInputElement).files.item(0);
    if(file) {
      console.log('file selected, emitting action');
      this.navpanelActionClickEvent.emit(action);
    } else {
      this.navpanelActionClickEvent.emit({
        actionName: 'close navpanel',
        actionType: NAVPANEL_ACTION_TYPE.CLOSE_NAVPANEL
      });
    }
  }

  processUSDZfileName(filename: string): string {
    let resultFileName = '';
    if(filename && filename.length > 5) {
      resultFileName = filename.substring(0, filename.length - 5)
    }
      return resultFileName
    }


  ngOnDestroy(): void {
    this.navpanelDestroyEvent.emit()
  }

}
