/* eslint-disable */
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { Inject, Injectable, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, switchMap } from 'rxjs';

import {
  Auth,
  signOut,
  signInWithPopup,
  getAuth,
  OAuthProvider,
  User,
  signInWithCredential,
  GoogleAuthProvider,
  getIdTokenResult,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail
} from '@angular/fire/auth';


import {
  collection,
  doc,
  Firestore,
  onSnapshot,
  query,
  where,
  DocumentData,
  getDoc,
  getDocs,
  QuerySnapshot,
  writeBatch,
  setDoc,
} from '@angular/fire/firestore';

// import * as firebase from 'firebase';
import { SplashScreen } from '@capacitor/splash-screen';
import { CARD_PURCHASE_STATUS, CARD_TYPE, FirebaseModelDto, ModelCardRendered, SUBSCRIPTION_STATUS, UploadModelCard, UserSubscriptionStatusDto } from 'src/app/models/card';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RollbarService, CustomRollbar } from './rollbarlog.service';
import { LOCALSTORAGE_VAR } from 'src/app/models/localstorage';
import { I3DWayUser } from 'src/app/models/user';
import { IGroupedCard } from 'src/app/models/groupedCard';
import { FirebaseStorage, getStorage } from 'firebase/storage';


export enum PURCHASE_PROCESS {
  PROCESSING = 'processing',
  DEFAULT = 'default',
  VERIFICATION = 'verification',
  ERROR = 'error',
  SUCCESS = 'success'
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  currentUser: I3DWayUser;
  firebaseUser: Subject<User> = new Subject();
  firebaseStorage: FirebaseStorage;
  userSubscription: Subscription;
  isSubscriptionRelatedBtnDisabled: boolean = true;


  isBasicTimeoutPassed: boolean = false;
  debugCount: number = 0;
  isLogoutHappened: boolean = false;

  notLoggedUserSubscription: Subject<void> = new Subject();

  private snapshotUnsubscribeFunctions: (() => void)[] = [];
  private firebaseModelsSubscriptionUnsubscribe: () => void;



  constructor(
    private afs: Firestore,
    private afAuth: Auth,
    public settingService: SettingsService,
    @Inject(RollbarService) private rollbar: CustomRollbar,
    private http: HttpClient
  ) {
    console.log('Fireauth.service constructor Init - authSUB ')

      this.afAuth.onAuthStateChanged((loggedUser: User) => {
        console.log('authSUB TRIGGERED IN init')
        console.log('authStateUser:', loggedUser);
        if (loggedUser) {
          this.firebaseStorage = getStorage();
          const loggedUser3DWay: I3DWayUser = loggedUser as I3DWayUser;
          loggedUser3DWay.subscriptionStatus = this.getSubscriptionStatusCacheInLocalStorage() ? this.getSubscriptionStatusCacheInLocalStorage() : SUBSCRIPTION_STATUS.NOT_SET;
          loggedUser3DWay.uploadsNumber = 0;
          loggedUser3DWay.uploadNumberLimit = 1;

          this.currentUser = loggedUser3DWay;


          this.firebaseUser.next(loggedUser);
          this.rollbar.info(`App started, current user: ${loggedUser.uid}, email: ${loggedUser.email}, cachedStatus: ${this.currentUser.subscriptionStatus}, uploadsNumber: ${this.currentUser.uploadsNumber}`);
          console.log('smthDataSubs; user: ', loggedUser.displayName, 'loggedUserObj: ', loggedUser);
          this.updateNumberOfUploads()
          this.setOriginalUidInLocalstorage(this.currentUser.uid);

        }
        else {
          SplashScreen.hide({ fadeOutDuration: 350 });
          this.notLoggedUserSubscription.next();
          console.log('***** USER NOT LOGGED IN ***** WAITING FOR AUTHORIZATION; ');
          this.clearOriginalUidInLocalstorage();
        }

      })

    setTimeout(() => {
      this.isBasicTimeoutPassed = true;
    }, 2000);
  }

  public patch3DWayUser(data: UserSubscriptionStatusDto): void {
    this.isSubscriptionRelatedBtnDisabled = false;
    this.setSubscriptionStatusCacheInLocalStorage(data.user_status);

    this.currentUser.subscriptionStatus = data.user_status;
    this.currentUser.uploadsNumber = data.uploads_count;
    this.currentUser.uploadNumberLimit = data.uploads_max_count;
    this.currentUser.subscriptionEndDateTime = data.billing_period_end ? data.billing_period_end : undefined;
    this.currentUser.isAutoRenewDisabled = data.autorenew_is_disabled ? data.autorenew_is_disabled : undefined;

    console.log('patch3DWayUser: ', this.currentUser);

    if(data.uploads_count < 3) {
      this.countUserModelsInFirebase().then((count) => {
        this.currentUser.uploadsNumber = count;
        console.log('countUserModelsInFirebase: ', count)
      });
    }
    // WORKAROUND
    if(data.uploads_count === data.uploads_max_count && data.user_status === SUBSCRIPTION_STATUS.TRIAL) {
      this.currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL_EXPIRED;
    }
  }

  public async getUserUid(): Promise<string> {
    if (this.settingService.customUid) {
      return this.settingService.customUid;
    }

    if (this.currentUser) {
      return this.currentUser.uid;
    } else {
      return new Promise((resolve, reject) => {
        const subscription = this.firebaseUser.subscribe((data) => {
          if (this.currentUser) {
            resolve(this.currentUser.uid);
            subscription.unsubscribe();
          }
        });
      });
    }
  }

  public getCurrentUserUID(): string {
    if(this.currentUser) {
      return this.currentUser.uid;
    }
    else {
      throw new Error('No user UID found');
    }
  }

  public getUserToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.currentUser) {
        resolve(this.currentUser.getIdToken());
      } else {
        const subscription = this.firebaseUser.subscribe(async (user) => {
          if (this.currentUser) {
            resolve(await this.currentUser.getIdToken());
            subscription.unsubscribe();
          }
        });
      }
    });
  }

  setSubscriptionStatusCacheInLocalStorage(value: string): void {
    localStorage.setItem(LOCALSTORAGE_VAR.SUBSCRIPTION_STATUS_CACHE, value.toString());
  }

  getSubscriptionStatusCacheInLocalStorage(): SUBSCRIPTION_STATUS {
    return localStorage.getItem(LOCALSTORAGE_VAR.SUBSCRIPTION_STATUS_CACHE) as SUBSCRIPTION_STATUS;
  }

  setOriginalUidInLocalstorage(value: string): void {
    localStorage.setItem(LOCALSTORAGE_VAR.ORIGINAL_UID, value.toString());
  }

  clearOriginalUidInLocalstorage(): void {
    localStorage.removeItem(LOCALSTORAGE_VAR.ORIGINAL_UID);
  }

  public clearLocalStorageData(): void {
    // unblock in future
    // localStorage.removeItem(LOCALSTORAGE_VAR.CUSTOM_UID);
    // localStorage.removeItem(LOCALSTORAGE_VAR.LOG_LEVEL);
    // localStorage.removeItem(LOCALSTORAGE_VAR.FRAME_NUMBER);
    localStorage.removeItem(LOCALSTORAGE_VAR.ORIGINAL_UID);
    localStorage.removeItem(LOCALSTORAGE_VAR.SUBSCRIPTION_STATUS_CACHE);
  }

  public updateNumberOfUploads(): void {
    this.currentUser.uploadsNumber++;
    if (this.currentUser.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBER && this.currentUser.uploadsNumber >= this.currentUser.uploadNumberLimit) {
      this.currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL_EXPIRED;
    }
  }

  public decreaseNumberOfUploads(): void {
    this.currentUser.uploadsNumber--;
  }

  async getDownloadUrlRef(downloadLink) {
    return downloadLink
  }

  public getDownloadUrl(ref: string): Promise<string> {
    return new Promise((resolve, reject) => {
      resolve('N/A');
    });
  }

  async createFirebaseuser(appleResponse): Promise<string> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('fullName');

    const credential = provider.credential({
      idToken: appleResponse.identityToken
    });

    const userCredential = await signInWithCredential(this.afAuth, credential);

    console.log('After sign in: ', userCredential);
    return userCredential.user.getIdToken();
  }

  async loginWithGoogle(): Promise<string> {
    try {
      console.log('loginWithGoogle started')
    const googleProvider = new GoogleAuthProvider().setCustomParameters({
      prompt: 'select_account'
  });
    // const googleProvider = new firebase.auth.GoogleAuthProvider();
    const googleUserCredential = await signInWithPopup(this.afAuth, googleProvider);
    return googleUserCredential.user.getIdToken()
    } catch (error) {
      throw error;
    }
  }

  async loginWithGoogleNative(token: string): Promise<string> {
    const auth = getAuth();
    const credential = GoogleAuthProvider.credential(token);
    const googleUserCredential = await signInWithCredential(auth, credential);
    return googleUserCredential.user.getIdToken();
  }

  async loginWithEmailAndPassword(email: string, password: string): Promise<string> {
    try {
      const auth = getAuth();
      const credentail = await signInWithEmailAndPassword(auth, email, password);
      const token = await credentail.user.getIdToken();
      return token;
    }
    catch (error) {
      throw error;
    }
  }

  async sendSignInLinkToEmail(email: string): Promise<void> {
    const actionCodeSettings = {
      url: 'https://3dway.io/emailSignIn',
      handleCodeInApp: true,
      iOS: {
        bundleId: 'com.dev-3dway-llc.app.dev'
      },
      android: {
        packageName: 'com.dev-3dway-llc.app.dev',
        installApp: true,
        minimumVersion: '12'
      },
    };

    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem('emailForSignIn', email);
    })
    .catch((error) => {
      console.error('Error sending email link:', error);
    });

  }

  async loginWithAppleWeb(): Promise<string> {
    try {
      console.log('loginWithAppleWeb started')
      const auth = getAuth();
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      const result = await signInWithPopup(auth, provider);
      console.log('result: ', result);
      return result.user.getIdToken();
    } catch (error) {
      console.error('Authentication failed:', error);
      // Handle the error or rethrow
      throw error;
    }
  }

  async getDocumentsWithLoadStatusNotFinished(): Promise<string[]> {
    const uid = await this.getUserUid();
    const firestore = this.afs; // Ensure this is the Firestore instance

    // Reference to the user's document
    const userDocRef = doc(firestore, '/userdata', uid);

    // Query 1: get documents where loadStatus != 'finished'
    const modelsCollectionRef1 = collection(userDocRef, 'models');
    const q1 = query(modelsCollectionRef1, where('loadStatusMobile', '!=', 'finished'));
    const querySnapshot1: QuerySnapshot<DocumentData> = await getDocs(q1);

    // Query 2: get documents where visibility == false
    const modelsCollectionRef2 = collection(userDocRef, 'models');
    const q2 = query(modelsCollectionRef2, where('visibility', '==', false));
    const querySnapshot2: QuerySnapshot<DocumentData> = await getDocs(q2);

    // Combine results
    const idsFromQuery1 = querySnapshot1.docs.map(doc => doc.id);
    const idsFromQuery2 = querySnapshot2.docs.map(doc => doc.id);

    const combinedIds = idsFromQuery1.filter(id => !idsFromQuery2.includes(id));

    console.log('****** DOCUMENTS: ', combinedIds);
    return combinedIds;
  }

  async deleteDocumentsByIds(documentIds: string[]): Promise<void> {
    const uid = await this.getUserUid();

    // Create a batch
    const batch = writeBatch(this.afs);

    documentIds.forEach(documentId => {
      // Get a reference to the document
      const docRef = doc(this.afs, `/userdata/${uid}/models/${documentId}`);
      batch.delete(docRef);
    });

    // Commit the batch
    await batch.commit();
  }

  async deleteDocumentsWithLoadStatusNotFinished(): Promise<void> {
    const documentIds = await this.getDocumentsWithLoadStatusNotFinished();
    await this.deleteDocumentsByIds(documentIds);
  }

  async countUserModelsInFirebase(): Promise<number> {
    const firestore = this.afs; // Get the Firestore instance

    const uid = await this.getUserUid();
    const userDocRef = doc(firestore, `userdata/${uid}`);  // Corrected path to user's document

    // Referencing the sub-collection
    const modelsSubCollection = collection(userDocRef, 'models');

    // Getting documents from the collection
    const querySnapshot = await getDocs(modelsSubCollection);
    const documentIds = querySnapshot.docs.map(doc => doc.id);
    console.log('****** DOCUMENTS: ', documentIds);
    return documentIds.length;
  }


  getFirebaseModelsWithPreprocessingStatusUpdates(): Observable<FirebaseModelDto[]> {
    const envLoadStatus = environment.type === 'mobile' ? 'loadStatusMobile' : 'loadStatusWeb';
    const userModelsPath = `userdata/${this.currentUser.uid}/models`;

    const statuses = ['readyToProcess', 'podStarted', 'modelDownload', 'rawThumbnailRendered', 'thumbnailRendered', 'thumbnailUploaded', 'rawVideoRendered', 'videoRendered'];

    // Construct the query
    const modelsRef = collection(this.afs, userModelsPath);
    const modelsQuery = query(modelsRef, where(envLoadStatus, 'in', statuses));

    // Create an observable to watch for real-time updates
    return new Observable<FirebaseModelDto[]>(observer => {
      const unsubscribe = onSnapshot(modelsQuery, snapshot => {
        const models = snapshot.docs.map(doc => ({
          id: doc.id,
          ...(doc.data() as FirebaseModelDto)
        }));
        observer.next(models);
      }, error => {
        observer.error(error);
      });

      // Store the unsubscribe function
      this.firebaseModelsSubscriptionUnsubscribe = unsubscribe;

      // Unsubscribe from Firestore listener when the observable is unsubscribed
      return unsubscribe;
    });
  }


  createSubscriptionForStatusChangesInFirebase(): Observable<any> {
    return new Observable<FirebaseModelDto>(observer => {
      const docRef = doc(this.afs, `userdata/${this.currentUser.uid}`);

      let isFirstSnapshot = true;

      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          if (isFirstSnapshot) {
            isFirstSnapshot = false; // Skip the first snapshot
            return;
          }
          const data = doc.data();
          const relevantData: Partial<UserSubscriptionStatusDto> = {
            user_status: data.user_status,
            user_status_upd: data.user_status_upd,
            billing_cycle_upd: data.billing_cycle_upd,
          };

          observer.next({ id: doc.id, ...relevantData } as any);
        } else {
          observer.error('Document does not exist');
        }
      }, (error) => {
        observer.error(error);
      });
      this.addSnapshotUnsubscribeFunction(unsubscribe);
      // Cleanup subscription on unsubscribe
      return { unsubscribe };
    });
  }



  public async getFirebaseModelsData(handler: Function, uploadingCard: IGroupedCard): Promise<void> {
    const fileName = uploadingCard.fileName;
    this.debugCount += 1;
    console.log('internal getFirebaseModelsData', this.debugCount);
    const uid = await this.getUserUid();

    const modelDocRef = doc(this.afs, `userdata/${uid}/models/${fileName}`);
    const docSnapshot = await getDoc(modelDocRef);

    if (docSnapshot.exists()) {
      console.log('DOC EXISTS');
      const unsubscribe = this.createSnapshotSubscription(handler, fileName, uid);
      this.addSnapshotUnsubscribeFunction(unsubscribe);
    } else {
      console.log('DOC NOT EXISTS');
      await this.patchDefaultModelInFirestore(uploadingCard, uid);
      const unsubscribe = this.createSnapshotSubscription(handler, fileName, uid);
      this.addSnapshotUnsubscribeFunction(unsubscribe);
    }
  }

  private createSnapshotSubscription(handler: Function, fileName: string, uid: string): () => void {
    const docRef = doc(this.afs, `userdata/${uid}/models/${fileName}`);
    const _unsubscribe = onSnapshot(docRef, {
      includeMetadataChanges: true
    }, (snapshot) => {
      if (snapshot.exists()) {
        handler(snapshot.data(), _unsubscribe);
        console.log('GetData From Firebase:', snapshot.data());
      }
    }, ex => {
      console.error('Error getting document:', ex);
    });
    return _unsubscribe;
    }

    // Add this method to add unsubscribe functions to the list
    public addSnapshotUnsubscribeFunction(unsubscribeFunction: () => void): void {
      this.snapshotUnsubscribeFunctions.push(unsubscribeFunction);
    }

    // Add this method to unsubscribe from all snapshot listeners
    public unsubscribeAllSnapshots(): void {
      this.snapshotUnsubscribeFunctions.forEach(unsubscribe => unsubscribe());
      this.snapshotUnsubscribeFunctions = [];
    }

    public unsubscribeFirebaseModelsUpdates(): void {
      if (this.firebaseModelsSubscriptionUnsubscribe) {
        this.firebaseModelsSubscriptionUnsubscribe();
        this.firebaseModelsSubscriptionUnsubscribe = null;
      }
    }

  async logout() {
    const currentUser = this.afAuth.currentUser;
    if (currentUser) {
      console.log('user', currentUser);

      const token = await currentUser.getIdToken();
      console.log(token);

      const idTokenResult = await getIdTokenResult(currentUser);
      console.log(idTokenResult);
    }

    await signOut(this.afAuth);
    this.unsubscribeAllSnapshots();
    this.unsubscribeFirebaseModelsUpdates();
    this.currentUser = undefined;
  }

  public async patchDefaultModelInFirestore(uploadingModel: IGroupedCard, uid: string): Promise<void> {
    // use carefully !!!!!;
    const fileName = uploadingModel.fileName;

    const modelDocRef = doc(this.afs, `userdata/${uid}/models/${fileName}`);

    const sampleModelData: UploadModelCard = {
      id: fileName,
      parentCollectionId: uploadingModel.linkedCollectionId ? uploadingModel.linkedCollectionId : 'undefined',
      modifiedDate: (new Date()).toString(),
      cardContentMobile: 'undefined',
      cardType: CARD_TYPE.MODEL,
      cardCategory: 'undefined',
      cardAuthor: uid,
      cardTitle: fileName,
      cardDescription: 'The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.',
      purchaseStatus: CARD_PURCHASE_STATUS.UPLOADED,
      cardPosterMobile: '',
      cardContentWeb: '',
      cardPosterWeb: '',
      cardPrice: 0,
      loadProgressMobile: '0',
      loadProgressWeb: '0',
      loadStatusMobile: 'modelDownload',
      loadStatusWeb: 'modelDownload',
      warningMobile: '',
      warningWeb: ''
    };
    try {
      await setDoc(modelDocRef, sampleModelData, { merge: true });
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document:', error);
    }

  }


}
