import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-renderlimit-select',
  templateUrl: './renderlimit-select.component.html',
  styleUrls: ['./renderlimit-select.component.scss']
})
export class RenderlimitSelectComponent {
  @Input() inputMaxElementsInRow: number = 5;
  renderLimitOptions = [10, 25, 50, 100, 250, null]; // Standard options
  selectedRenderLimit: number | null = 25; // Default to 25

  constructor(private userDataService: UserDataService) {
    // Subscribe to the service to get the current render limit
    this.userDataService.renderLimit$.subscribe(limit => {
      this.selectedRenderLimit = limit;
    });
  }

  setRenderLimit(limit: number | null) {
    this.userDataService.setRenderLimit(limit);
  }

  isSelected(limit: number | null) {
    return this.selectedRenderLimit === limit;
  }

}
