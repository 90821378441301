import { Subscription } from 'rxjs';
import { ModelsCardComponent } from '../../shared/components/models-card/models-card.component';
import { FirebaseAuthService } from '../../shared/providers/firebase-auth.service';
import { UtilityFunctionsService } from '../../shared/providers/utility-functions.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { ICardCollection, ICollectionEditOptions } from 'src/app/models/collection';
import { NAVPANEL_ACTION_TYPE, INavpanelAction, CARDS_MODE, COLLECTION_CONTROL_BTN, CARD_CONTROL_BTN, ICardAction, ICard, ModelCardRenderedSelection, CARD_PURCHASE_STATUS, SWITCH_PANEL_ITEM, ICardFeedModificator } from '../../models/card';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { ModelCardRendered } from 'src/app/models/card';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { fullscreenPanelAnimation, inOutAnimation, modelFeedAnimation, navpanelAnimation } from 'src/app/shared/providers/animations-list';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { WebCollectionDetailsComponent } from '../web-collection-details/web-collection-details.component';
import { CardSelectPanelComponent } from 'src/app/shared/components/card-select-panel/card-select-panel.component';
import { WebNavService } from '../webnavigation.service';
import { WebaddfromComponent } from '../webaddfrom/webaddfrom.component';
import { WebDialogData, WebpopupdialogComponent } from '../webpopupdialog/webpopupdialog.component';


@Component({
  selector: 'app-webedit-collection',
  templateUrl: './webedit-collection.component.html',
  styleUrls: ['./webedit-collection.component.scss'],
  animations: [
    navpanelAnimation,
    inOutAnimation,
    fullscreenPanelAnimation,
    modelFeedAnimation
  ]
})
export class WebEditCollectionComponent implements OnInit, OnDestroy {

  @ViewChild('modelCardsFeed')
  public modelCardsFeed: ModelsCardComponent;

  @ViewChild('webCollectionDetailsComponent', { static: false })
  public collectionDetailsComponent: WebCollectionDetailsComponent;

  @ViewChild('cardSelectRef')
  public cardSelectRef: CardSelectPanelComponent;

  @ViewChild('addFromLibrary')
  public addFromLibrary: WebaddfromComponent;

  FOOTER_MODE = FOOTER_MODE;
  CARDS_MODE = CARDS_MODE;
  NAVPANEL_ACTION_TYPE = NAVPANEL_ACTION_TYPE;
  COLLECTION_CONTROL_BTN = COLLECTION_CONTROL_BTN;

  public cardSelectTitle: string;
  public isCardSelectShown: boolean = false;

  public feedModificatorSetup: ICardFeedModificator = { isFeedForEditCollection: true };

  public isModelFeedShown: boolean = false;

  public editCollectionSource: ICardCollection;

  public cardFeedCardStartPosition: number;
  public modelCardsMock: ModelCardRendered[];
  public cardToEdit: ModelCardRenderedSelection;

  // public editCollectionAdditionalfooterData: IAdditionalFooterData = {priceData: undefined};

  public filterCardMode: NAVPANEL_ACTION_TYPE

  public collectionEditOptions: ICollectionEditOptions = {
    mode: 'edit'
  }

  public cardSelectData: ModelCardRenderedSelection[];

  public editCollectionSnapshot: ICardCollection;
  public sourceCollectionId: string;
  public isSubscriptionSplashShown: boolean;
  public isRejectedScreenShown: boolean;

  public uploadServiceSubscription: Subscription;
  public uploadErrorSub: Subscription;
  public webpanelSwitchPanelChangeSubscription: Subscription;
  uploadStatusSub: Subscription;

  constructor(
    public mockDataService: MockDataService,private readonly router: Router, public pageScrollService: PageScrollService,
    public firebaseAuth: FirebaseAuthService,
    public webNavService: WebNavService,
    public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService,
    public userDataService: UserDataService, private readonly route: ActivatedRoute,
    public dialog: MatDialog, public utilityService: UtilityFunctionsService,
    public cdr: ChangeDetectorRef
  ) {
   }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.sourceCollectionId = queryParams.get('collectionId');
      if (this.sourceCollectionId) {
        this.editCollectionSource = this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)
        this.modifyEditCollectionNavPanelItems();

        // THIS LOGIC WRITTEN ONLY FOR DEBUG PURPOSES;
        if (this.editCollectionSource === undefined) {
          this.editCollectionSource = this.utilityService.generateMockCollection();
          const checkInterval = setInterval(() => {
            if (this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)) {
              setTimeout(() => {
                this.editCollectionSource = this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId);
                // this.onCollectionDetailsThumbClick(0); // DEBUG
              }, 1000);
              clearInterval(checkInterval);
            }
          }, 1000);
        }
      } else {
        this.editCollectionSource = this.userDataService.allUserDraftCollections[this.userDataService.allUserDraftCollections.length - 1];
      }
    });

     this.uploadErrorSub = this.uploadService.uploadErrorSubscription.subscribe(()=> {
      this.handleNavpanelAction({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
    })

      this.uploadStatusSub = this.uploadService.uploadStatusSubscription.subscribe(()=> {
        this.cdr.detectChanges();
      });

     this.uploadServiceSubscription = this.uploadService.latestUploadedModelSubscription.subscribe((uploadStatus) => {
        // DIRTY HACK;
        setTimeout(() => {
          console.log('uploadServiceSubscription UPDATE TRIGGERED-> ', uploadStatus);
         this.editCollectionSource = this.userDataService.allUserDraftCollections.find(col => col.collectionId === this.sourceCollectionId)
        }, 500);
     });

     this.webNavService.currentWebSwitchPanelMode = SWITCH_PANEL_ITEM.ALL;

     this.webpanelSwitchPanelChangeSubscription = this.webNavService.webNavSwitchPanelChange.subscribe((switchPanelItem) => {
        this.handleWebSwitchPanelChange(switchPanelItem);
      });

     this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;

     this.cardSelectData = this.userDataService.allUserCards;
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    // switch (this.filterCardMode) {
    //   case NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS :
    //     this.modelCardsMock = this.editCollectionSource.collectionCards;
    //   break;
    //   case NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS :
    //     this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => this.userDataService.isCardExitsInSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED );
    //   break;
    //   case NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS :
    //     this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED );
    //   break;
    //   case NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS :
    //     this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED );
    //   break;
    //   case NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS :
    //     this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED ||
    //     card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED );
    //   break;
    //   default:
    //     break;
    // }

    // TO UPDATE FILTERED ITEMS FOR CORRECT POSITIONING;
    this.handleWebSwitchPanelChange(this.webNavService.currentWebSwitchPanelMode);

    if(this.collectionEditOptions.mode === 'active-edit') {
      // HERE LOGIC TO DELETE ONLY AFTER SAVE;

      this.userDataService.addCardToDeleteBuffer(this.editCollectionSource.collectionCards[thumbNumber]);
      this.editCollectionSource = this.userDataService.deleteCardFromCollection(this.editCollectionSource.collectionCards[thumbNumber], this.editCollectionSource)
    }
    if(this.collectionEditOptions.mode === 'edit') {
      this.cardFeedCardStartPosition = thumbNumber;
      // this.modelCardsMock = this.editCollectionSource.collectionCards;
      this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
      this.isModelFeedShown = true;
    }
    console.log(' Edit-collection onCollectionDetailsThumbClick')
  }


  public onWebEditCollectionNavBtnClick(navbarBtn: NAVBAR_BTN): void {
    switch (true) {
      case navbarBtn === NAVBAR_BTN.UPLOAD:
        this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.IN_REVIEW:
        this.onCollectionDetailsControlButtonClicked(COLLECTION_CONTROL_BTN.EDIT);
        break;
      case navbarBtn === NAVBAR_BTN.INVITE_COLLABORATOR:
        const collaborator = this.userDataService.addMockCollaborator();
        if(collaborator) {
          this.editCollectionSource.collaborators.unshift(collaborator);
          this.utilityService.showSnackBar('Collaborator successfully invited!', 0)
        } else {
          this.utilityService.notImplemented();
        }
        break;
      case navbarBtn === NAVBAR_BTN.REMOVE_COLLABORATOR:
        // this.openRemoveCollaboratorDialog();

        this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.FREE_TRIAL:
        this.userDataService.tryForFreeClicked();
        this.isSubscriptionSplashShown = false;
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.utilityService.showImportantSnackBar('3 models free, subscribe after to store your entire portfolio', 150);
        if(this.settingService.devicePlatform === 'ios')  {
          setTimeout(() => {
            this.uploadService.pickAndUploadFileNative();
          }, 2750);
        }
        break;
        case navbarBtn === NAVBAR_BTN.SUBSCRIBE:
          this.utilityService.notImplemented();
          break;
        case navbarBtn === NAVBAR_BTN.ADD:
        console.log('NAVBAR_BTN.ADD');
        break;
      case navbarBtn === NAVBAR_BTN.NOT_IMPL:
        this.utilityService.notImplemented();
        break;
      case navbarBtn === NAVBAR_BTN.SAVE && this.webNavService.footerMode === FOOTER_MODE.RENAME_ITEM:
        console.log('SAVE CLICK ON RENAME CLICKED')
          const editCardIndex = this.editCollectionSource.collectionCards.findIndex((card) => {
            return card.cardPosterMobile === this.cardToEdit.cardPosterMobile && card.cardTitle === this.cardToEdit.cardTitle
          })

          this.cardService.updateModelPostRequest(this.cardToEdit).then((res) => {
            this.utilityService.showSnackBar('Model renamed', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on rename: ${JSON.stringify(error.error)}`, 500));


          this.editCollectionSource.collectionCards[editCardIndex].modifiedDate = this.utilityService.getCurrentDateWithNanoseconds();
          // this.editCollectionSource.collectionCards[editCardIndex].cardTitle = this.lastNameInputValue === '' ? this.userDataService.getNewUploadFileDefaultName() : this.lastNameInputValue;
          this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
        break;
      // ADD FROM USER LIBRARY -> SAVE BTN
      case navbarBtn === NAVBAR_BTN.SAVE && this.isCardSelectShown:

        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        const selectedCards = this.addFromLibrary.cardsToSelect.filter((cardToFilter => cardToFilter.isSelected));
        this.editCollectionSource = this.userDataService.organizeAllCardsToDraftCollection(selectedCards, this.editCollectionSource);
        this.isCardSelectShown = false

         this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
          console.log(res);
          this.utilityService.showSnackBar('Collection updated', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));

        break;
      case navbarBtn === NAVBAR_BTN.SAVE:
        console.log('SAVE CLICK ON BASIC EDIT COLLECTION')
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.collectionEditOptions.mode = 'edit';
        this.collectionEditOptions.isDescriptionEditing = false;
        this.userDataService.updateUserCollectionInUserDrafCollections(this.editCollectionSource);
          this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
            this.utilityService.showSnackBar('Collection updated', 500);
          }).catch(err => {this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(err.error)}` , 500)})
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.collectionEditOptions.mode === 'active-edit':
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.userDataService.cleanCardToDeleteBuffer();
        this.collectionEditOptions.mode = 'edit';
        this.collectionEditOptions.isDescriptionEditing = false;
        this.restoreFromSnapshot();
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isCardSelectShown:
        console.log('BACK BTN');
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.restoreFromSnapshot();
        this.isCardSelectShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK && this.isSubscriptionSplashShown:
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isSubscriptionSplashShown = false
        break;
      case navbarBtn === NAVBAR_BTN.BACK:
        this.router.navigateByUrl('/');
        break;
    }
  }

  public onCollectionDetailsControlButtonClicked(buttonName: COLLECTION_CONTROL_BTN): void {
    switch (buttonName) {
      case COLLECTION_CONTROL_BTN.EDIT_ADD_DESCRIPTION:
        this.collectionEditOptions.isDescriptionEditing = true;
        setTimeout(() => {
        // this.collectionDetailsComponent.selectDescriptionTextarea();
        }, 150);
        break;
      case COLLECTION_CONTROL_BTN.EDIT_CHANGE_VIDEO:
        this.utilityService.notImplemented();
        break;
      case COLLECTION_CONTROL_BTN.COLLABORATIVE_SHARE:
        if(this.editCollectionSource.accessType === 'Private') {
          // this.openCollaborativeConfirmationDialog();
          this.utilityService.notImplemented();
        }
        if(this.editCollectionSource.accessType === 'Collaborative') {
          this.utilityService.showShareDialog(this.editCollectionSource.collectionTitle)
        };
        break
      default:
        break;
    }
  }

  public onCardControlBtnClicked(action: ICardAction): void {
    this.cardToEdit = action.card;
    console.log('card clicked', action);

    switch (action.controlAction) {

      case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
        this.isRejectedScreenShown = true;
        break;

      case CARD_CONTROL_BTN.PUBLISH_SINGLE_CARD:
        const selectedCardsToPublish = [this.editCollectionSource.collectionCards.find(card => card.id === action.card.id)];
        selectedCardsToPublish.forEach((card)=>card.parentCollectionId = this.sourceCollectionId)
        this.userDataService.sendModelsForReview(selectedCardsToPublish);
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;
        this.utilityService.showSnackBar('Models successfuly sent to review', 0);
        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;
        this.filterUnlikeCards();
        break;
      case CARD_CONTROL_BTN.LIKE_DRAFT_CARD:
        const cardToLike = this.editCollectionSource.collectionCards.find(card => card.cardContentMobile === action.card.cardContentMobile);
        cardToLike.purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_LIKED;
        this.modelCardsMock = this.editCollectionSource.collectionCards;

        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));
        break;
      case CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD:
        const cardToUnlike = this.editCollectionSource.collectionCards.find(card => card.cardContentMobile === action.card.cardContentMobile);
        cardToUnlike.purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED;
        this.modelCardsMock = this.editCollectionSource.collectionCards;

        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(error.error)}`, 500));
        break;
      case CARD_CONTROL_BTN.DELETE_MODEL:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      case CARD_CONTROL_BTN.RENAME_CARD:
        this.openRenameDialog();
        break;
      case CARD_CONTROL_BTN.REMOVE_CARD_FROM_COLLECTION:
        this.openRemoveFromCollectionCardConfirmationDialog(this.cardToEdit);
        break;
      default:
        break;
    }
  }

  private filterUnlikeCards(): void {
    // this.userDataService.deleteCardFromCollection(this.editCollectionSource.collectionId, this.cardToEdit.cardId);
    this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED).forEach(modelCardUnLiked => {
      this.userDataService.deleteCardFromCollection(modelCardUnLiked, this.editCollectionSource)
    });
    this.editCollectionSource.collectionCards = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED);
    this.modelCardsMock = this.modelCardsMock.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.DRAFT_NOT_LIKED);
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.MAKE_COLLABORATIVE:
        this.utilityService.notImplemented();
        // this.openCollaborativeConfirmationDialog();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.MAKE_NON_COLLABORATIVE:
        this.utilityService.notImplemented();
        // this.openNonCollaborativeConfirmationDialog();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.EDIT_COLLECTION:
        this.webNavService.footerMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION;
        this.webNavService.currentWebSwitchPanelMode = SWITCH_PANEL_ITEM.ALL;
        this.createCollectionSnapshot();
        this.collectionEditOptions.mode = 'active-edit';
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
        if(this.editCollectionSource.collectionDescription) this.collectionEditOptions.isDescriptionEditing = true;
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_COLLECTION:
        this.openDeleteCollectionConfirmationDialog();

        break;
      case actionName === NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING:
        this.openCancelPublishingCardConfirmationDialog(this.cardToEdit)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.ADD_FROM_LIBRARY:
        this.createCollectionSnapshot();
        // this.cardSelectData = JSON.parse(JSON.stringify(this.mockDataService.editCollectioncardSelectData));
        this.cardSelectData = this.userDataService.allUserCards;
        this.cardSelectData?.forEach(cardRaw => cardRaw.isSelected = false);
        this.webNavService.footerMode = FOOTER_MODE.ADD_FROM_LIBRARY;
        this.cardSelectTitle = 'Add to Collection.';
        this.isCardSelectShown = true;

        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.editCollectionSource.collectionCards.splice(this.editCollectionSource.collectionCards.findIndex(card => card.id === this.cardToEdit.id), 1);
        this.userDataService.updateUserCollectionInUserDrafCollections(this.editCollectionSource);
        this.isModelFeedShown = false;
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
          this.utilityService.showSnackBar('Collection updated, model removed', 500);
        }).catch(err => {this.utilityService.showErrorSnackBar(`Error occured on update: ${JSON.stringify(err.error)}` , 500)})
        // this.utilityService.notImplemented();
        // this.modelCardsFeed.dispatchCurrentCardLikeClick()
        // this.userDataService.removeFromSavedCards(this.cardToEdit);
        break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL:
          console.log('NAVPANEL UPLOAD ACTION');
          this.utilityService.notImplemented();
          break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_NOT_SUBSCRIBED:
          this.isSubscriptionSplashShown = true;
          this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          // this.utilityService.notImplemented('You have to subscribe for 3DWay Base plan to be able to upload models.')
          break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_DISABLED:
          // this.isSubscriptionSplashShown = true;
          this.utilityService.notImplemented('Only 1 model processing allowed for trial users at one time; Please, wait model to be processed')
          break;
        case actionName === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL_LIMIT_REACHED:
          this.isSubscriptionSplashShown = true;
          this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED:
          this.isSubscriptionSplashShown = true;
          this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.SHOW_SUBSCRIBE:
          this.isSubscriptionSplashShown = true;
          this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
          break;
        case actionName === NAVPANEL_ACTION_TYPE.DOWNLOAD_MODELS:
          console.log(' COLLECTION_CONTROL_BTN.DOWNLOAD_MODEL')
          this.cardSelectTitle = 'Select Models';
          this.webNavService.footerMode = FOOTER_MODE.CARD_DOWNLOAD_PURCHASED;
          this.cardSelectData = JSON.parse(JSON.stringify(this.editCollectionSource.collectionCards.filter(cardRaw => cardRaw.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED)));
          this.cardSelectData.forEach(cardRaw => {
            cardRaw.isSelected = false;
            cardRaw.isDisabled = undefined;
          });
          this.isCardSelectShown = true;
          break;
      case actionName === NAVPANEL_ACTION_TYPE.PUBLISH_MODELS:
        this.cardSelectTitle = 'Select Models.'
        this.cardSelectData = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED)
        this.webNavService.footerMode = FOOTER_MODE.PUBLISH_SELECTED_MODELS;
        // this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.ADD_DESCRIPTION:
        this.collectionEditOptions.mode = 'active-edit';
        this.createCollectionSnapshot();
        this.collectionEditOptions.isDescriptionEditing = true;
        this.webNavService.footerMode = FOOTER_MODE.ACTIVE_EDIT_COLLECTION;
        setTimeout(() => {
          // this.collectionDetailsComponent.selectDescriptionTextarea();
        }, 100);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.ADD_VIDEO:
        this.editCollectionSource.collectionContentMobile = JSON.parse(JSON.stringify(this.mockDataService.basicCollectionVideo));
        this.editCollectionSource.collectionPosterMobile = this.mockDataService.basiccollectionPosterMobile
        this.editCollectionSource.showFullscreenBackgroundPreview = true;
        console.log('add video triggered');
        break;
      case actionName === NAVPANEL_ACTION_TYPE.REMOVE_CARD_FROM_COLLECTION:
        this.openRemoveFromCollectionCardConfirmationDialog(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_CARD:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      default:
        break;
    }
    console.log(action.actionName);
  }

  public handleWebSwitchPanelChange(switchPanleBtn: SWITCH_PANEL_ITEM): void {
    switch (switchPanleBtn) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS;
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) =>
          card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED ||
          card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW ||
          card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED ||
          card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED ||
          card.purchaseStatus === CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT
        );
        this.cardSelectData = this.userDataService.uploadedCards;

        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS;
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => this.userDataService.isCardExitsInSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED );
        this.cardSelectData = this.userDataService.getSavedCards();

        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS;
        this.modelCardsMock = this.editCollectionSource.collectionCards.filter((card) => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED );
        this.cardSelectData = this.userDataService.userPurchasedCards;

        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
         this.modelCardsMock = this.editCollectionSource.collectionCards;
        this.cardSelectData = this.userDataService.allUserCards;

        break;
      default:
        break;
    }
  }

  public onCardSelectSwitchPanelClicked(switchPanleBtn: SWITCH_PANEL_ITEM): void {


    switch (switchPanleBtn) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.cardSelectData = this.userDataService.uploadedCards;
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.cardSelectData = this.userDataService.getSavedCards();
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.cardSelectData = this.userDataService.userPurchasedCards;
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.cardSelectData = this.userDataService.allUserCards;
        break;
      default:
        break;
    }
  }


  public createCollectionSnapshot(): void {
    this.editCollectionSnapshot = JSON.parse(JSON.stringify(this.editCollectionSource));
    console.log('createCollectionSnapshot done');
  }

  public restoreFromSnapshot(): void {
    this.editCollectionSource = Object.assign(this.editCollectionSource, this.editCollectionSnapshot);
    console.log('restoreFromSnapshot done');
  }

  public onCardSeletected(cardsToPurch: ModelCardRenderedSelection[]): void {
    console.log('CARDS LENGTH SELECTED: ', cardsToPurch.length);
    //   // setting additional purchase data;
    //   console.log('CARDS LENGTH SELECTED: ', cardsToPurch.length);
    //   const isAllCardsSelected = this.editCollectionSource.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.DEFAULT_3DWAY).length === cardsToPurch.length;
    //   if(isAllCardsSelected) this.editCollectionAdditionalfooterData.selectToggleLabel = 'Cancel';
    //   else this.editCollectionAdditionalfooterData.selectToggleLabel = 'Select All';

    // if(this.webNavService.footerMode === FOOTER_MODE.CARD_BUY_SECTION) {
    //   const selectedCardPrice = cardsToPurch.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED ).reduce((acc, val) => {
    //     return acc = acc + val.cardPrice;
    //   }, 0)
    //   this.editCollectionAdditionalfooterData.priceData = '$' + selectedCardPrice.toString() + '.00' ;
    //   console.log(selectedCardPrice)
    // }
    // if(this.webNavService.footerMode === FOOTER_MODE.CARD_DOWNLOAD_PURCHASED) {
    //   const selectedForDownloadPrice = cardsToPurch.reduce((acc, val) => {
    //     return acc = acc + val.cardPrice;
    //   }, 0)
    //   this.editCollectionAdditionalfooterData.priceData = '$' + selectedForDownloadPrice.toString() + '.00' ;
    //   console.log(selectedForDownloadPrice)
    // }
  }


  public onCancelRejectedScreenClicked(): void {
    this.isRejectedScreenShown = false;
  }

  public updateUserSelectedCards(): void {
    const selectedCards = this.cardSelectRef.cardsToSelect.filter((cardToFilter => cardToFilter.isSelected));
    this.editCollectionSource.collectionCards.push(...JSON.parse(JSON.stringify(selectedCards)));
    this.cardSelectRef.cardsToSelect.forEach((col) => col.isSelected = false);
  }

  public openDeleteCollectionConfirmationDialog() {
    const collectionName = this.editCollectionSource.collectionTitle ? this.editCollectionSource.collectionTitle : 'Collection';
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to delete this collection permanently?`,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.cardService.deleteUserCollection(this.editCollectionSource.collectionId).then((resp) => {
          this.utilityService.showSnackBar('Collection deleted.', 0);
        }).catch(err => {this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(err.error)}` , 500)})

        // Delete collection:
        this.userDataService.deleteCollectionFromDrafts(this.editCollectionSource);
        this.router.navigateByUrl('/');
      }
    });
  }

  public openCancelPublishingCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to cancel publishing model "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // OUTDATED LOGIC !!!!!!! SHOULD BE REWORKED !!!!!!!

        this.userDataService.cancelModelPublishing(card);
        this.webNavService.footerMode = FOOTER_MODE.LIBRARY;
        const indx = this.editCollectionSource.collectionCards.findIndex((findCard) => { return findCard.id === card.id});
        this.editCollectionSource.collectionCards[indx].purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;

        this.cardService.cancelReviewModelPostRequest(card).then((resp) => {
            this.utilityService.showSnackBar('Review cancelled succesfully.', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on cancelling review: ${JSON.stringify(error.error)}`, 500));

      }
    });
  }

  public openRenameDialog() {
    const dialogConfig: WebDialogData = {
      heading: 'Rename Model',
      mainBtnTitle: 'Rename',
      inputPlaceHolder: 'Enter new name',
      inputValue: this.cardToEdit.cardTitle
    }
    const dialogRef = this.dialog.open(WebpopupdialogComponent, {data: dialogConfig, restoreFocus: false, panelClass: 'webpopup-overlay'},);

    const sub = dialogRef.componentInstance.webpopupdialogueMainBtnClick.subscribe((newName: string) => {
      console.log('Model to rename:', this.cardToEdit);
      this.updateCurrentCardTitle(newName);
      this.dialog.closeAll();
      sub.unsubscribe();
    });
  }

  public updateCurrentCardTitle(newName: string) {
    const editCardIndex = this.userDataService.uploadedCards.findIndex((card) => {
      return card.cardPosterMobile === this.cardToEdit.cardPosterMobile && card.cardTitle === this.cardToEdit.cardTitle
    })
    this.cardToEdit.cardTitle = newName;
    this.userDataService.uploadedCards[editCardIndex].modifiedDate = this.utilityService.getCurrentDateWithNanoseconds();
    this.userDataService.uploadedCards[editCardIndex].cardTitle = newName;

    this.cardService.updateModelPostRequest(this.cardToEdit).then((res) => {
      this.utilityService.showSnackBar('Model renamed succesfully', 500);
    }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on rename: ${JSON.stringify(error.error)}`, 500));
  }

  public openRemoveFromCollectionCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        // dialogText: `Do you want to remove "${card.cardTitle}" from collection ? It will not remove model from your library`,
        dialogText: 'Do you want to delete this model from this collection?',
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.editCollectionSource = this.userDataService.deleteCardFromCollection(card, this.editCollectionSource)
        this.cardService.updateCollectionPostRequest(this.editCollectionSource).then((res) => {
          console.log(res);
          this.utilityService.showSnackBar('Model removed; Collection updated', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on remove and upadte collection: ${JSON.stringify(error.error)}`, 500));

        this.isModelFeedShown = false;
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
      }
    });
  }

  public openDeleteCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Do you want to delete "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.cardService.deleteModelPostRequest(card).then((resp) => {
          this.utilityService.showSnackBar('Model deleted succesfully.', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(error.error)}`, 500));

        this.userDataService.deleteCardFromCollection(card, this.editCollectionSource)
        this.isModelFeedShown = false;
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
      }
    });
  }

  public onModelCardPressed() {
    this.webNavService.footerMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.webNavService.footerMode = this.webNavService.footerMode === FOOTER_MODE.DEFAULT || this.webNavService.footerMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public filterUploadCards = (groupedCard: IGroupedCard) => {
    if(groupedCard.linkedCollectionId !== this.editCollectionSource?.collectionId) return false;
    return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING
  }

  public modifyEditCollectionNavPanelItems(): void {
      this.webNavService.editCollectionAddWebPanelItems = this.modifyActionWithCollectionId(this.webNavService.editCollectionAddWebPanelItems, this.editCollectionSource?.collectionId);
      console.log('Modified edit collection items:', this.webNavService.editCollectionAddWebPanelItems );
  }

  private modifyActionWithCollectionId(sourceActions: INavpanelAction[], collectionId: string): INavpanelAction[] {
    return sourceActions.map(action => {
      return { ...action, optionalData: {collectionId} }
    });
  }

  ngOnDestroy(): void {
    this.uploadServiceSubscription.unsubscribe();
    this.webpanelSwitchPanelChangeSubscription.unsubscribe();
    this.uploadErrorSub.unsubscribe();
    this.uploadStatusSub.unsubscribe();
  }



}
