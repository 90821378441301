<ng-container *ngIf="sourceCollection?.preloadSettings?.enablePreload">
  <ng-container *ngFor="let link of imagesToPreloadSanitized; let indexOfelement = index">
    <link rel="preload" as="image" [href]="link.urlSanitized" (load)="preloadFinished(indexOfelement)" (error)="preloadError($event)" type="image/webp" />
  </ng-container>
</ng-container>

<div class="collection-details-content" #collectionDetailsRef cdkScrollable>
    <img *ngIf="sourceCollection?.showFullscreenBackgroundPreview " class="collection-details-preview" [src]="getPoster(sourceCollection.collectionPosterMobile)">
    <div *ngIf="sourceCollection?.showFullscreenBackgroundPreview && sourceCollection?.collectionPosterMobile" class="overscroll-background-gradient"></div>
  <div class="collection-details-description-wrapper">
    <div class="collDetails-card-description" [appResponsiveClass]="'single-gallery'">
      <div class="collDetails-title-row" [ngClass]="{'active-edit': collectionEditOptions?.mode === 'active-edit'}">
        <h1 *ngIf="collectionEditOptions?.mode !== 'active-edit'" class="collDetails-card-title">{{sourceCollection?.collectionTitle}}</h1>
        <input *ngIf="collectionEditOptions?.mode === 'active-edit' && sourceCollection" [(ngModel)]="sourceCollection.collectionTitle" fxFlex="1 1 100" class="collection-title-input" [ngClass]="{'posterShown': sourceCollection.collectionPosterMobile}" placeholder="Collection Name" type="search" #searchInput />
      </div>
      <ng-container *ngIf="collectionEditOptions?.mode !== 'active-edit'">
        <div class="collDetails-card-description-section">
          <p class="collDetails-card-about-short">{{sourceCollection?.collectionDescription}}</p>
        </div>
      </ng-container>

      <ng-container *ngIf="collectionEditOptions?.mode === 'active-edit'">
        <!-- <h4 class="change-video-btn" (click)="onControlButtonClick($event, COLLECTION_CONTROL_BTN.EDIT_CHANGE_VIDEO)">Change Video</h4> -->
        <div class="collDetails-card-description-section">
          <textarea #collectionDescriptionTextareaRef [(ngModel)]="sourceCollection.collectionDescription" maxlength="100"  [ngClass]="{'collDetails-card-about-short-textarea': true, 'editing': collectionEditOptions?.isDescriptionEditing}" [hidden]="!collectionEditOptions?.isDescriptionEditing"></textarea>
          <button *ngIf="collectionEditOptions?.isDescriptionEditing != true"  class="add-description" (click)="onControlButtonClick($event, COLLECTION_CONTROL_BTN.EDIT_ADD_DESCRIPTION)">Add description</button>
        </div>
      </ng-container>

    </div>
    <ng-container *ngIf="collectionEditOptions?.mode === 'active-edit'">
      <div class="edit-row" [ngClass]="{'active-edit': collectionEditOptions?.isDescriptionEditing}"  fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <div class="edit-item"><span class="collection-type">{{sourceCollection?.accessType}}</span></div> -->
        <div class="edit-item"><span class="collection-type"> </span></div>
      </div>
    </ng-container>

    <div class="collection-details-description-background"></div>

  </div>

  <!-- <div class="gallery-thumb-wrapper" [ngClass]="getThumbWrapperCombinedClass()" [appResponsiveClass]="'single-gallery'" fxLayout="row wrap">
    <ng-content #uploadCard></ng-content>
    <div class="gallery-thumb sourceCards" [ngClass]="getCombinedClass(sourceCard)"  (click)="onThumbCardClickWithDisableCheck(sourceCard)" *ngFor="let sourceCard of sourceCards | sortByDate, let thumbIndex = index" [attr.disabled]="sourceCard?.isDisabled" [ngStyle]="{'background-image': 'url(' + sourceCard?.cardPosterMobile + ')'}">
      <mat-icon *ngIf="galleryMode === GALLERY_MODE.SELECTION && sourceCard?.isSelected || galleryMode === GALLERY_MODE.SELECTION && isCardInDisabledArray(sourceCard)" [@inOutAnimation] class="select-checkbox" svgIcon="checkbox-icon"></mat-icon>
      <div *ngIf="settingService.showDateModified" class="date-cover">{{sourceCard.modifiedDate | date:'medium'}}</div>
      <div *ngIf="showCardPrice && sourceCard?.cardPrice" class="price-cover" (click)="onPriceTagClicked($event, sourceCard)" [ngClass]="{'selected' : sourceCard?.isSelected}">
        ${{sourceCard?.cardPrice}}.00
      </div>
    </div>
  </div> -->

  <div class="models-thumb-wrapper weblayout" [appResponsiveClass]="'single-gallery'" [ngClass]="{'active-edit': collectionEditOptions?.mode === 'active-edit'}" fxLayout="row wrap" cdkDropListGroup  [cdkDropListGroupDisabled]="collectionEditOptions?.mode !== 'active-edit'"  >
    <ng-content *ngIf="collectionEditOptions?.mode !== 'active-edit'" #uploadCard></ng-content>

    <div class="model-thumb weblayout"
      (click)="onThumbClick(thumbIndex)" *ngFor="let thumb of sourceCollection?.collectionCards | callback: filterCards, let thumbIndex = index" cdkDropList [cdkDropListData]="thumbIndex" [ngStyle]="{'background-image': 'url(' + thumb.cardPosterMobile + ')'}">
        <div class="internal-thumb"  [ngClass]="{'inReview' : thumb.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW, 'rejected' : thumb.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED }"  [ngStyle]="{'background-image': 'url(' + thumb.cardPosterMobile + ')'}"  cdkDrag [cdkDragData]="thumbIndex" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragReleased()" (cdkDragEntered)="dragEntered($event)" >

          <span class="example-custom-placeholder" *cdkDragPlaceholder></span>
            <svg *ngIf="collectionEditOptions?.mode === 'active-edit'" class="delete-thumb-btn" (click)="onDeleteSignClick(thumbIndex)" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9927 23C13.5696 23 15.0522 22.7023 16.4404 22.107C17.8286 21.5117 19.0524 20.6816 20.1117 19.6168C21.171 18.552 22.0005 17.3274 22.6003 15.9432C23.2001 14.5589 23.5 13.0779 23.5 11.5C23.5 9.92214 23.2001 8.44108 22.6003 7.05682C22.0005 5.67257 21.1686 4.44803 20.1044 3.38322C19.0403 2.3184 17.8165 1.48833 16.4331 0.892983C15.0497 0.297661 13.5648 0 11.9782 0C10.411 0 8.93333 0.297661 7.54511 0.892983C6.1569 1.48833 4.93555 2.3184 3.88108 3.38322C2.8266 4.44803 1.99947 5.67257 1.39968 7.05682C0.799895 8.44108 0.5 9.92214 0.5 11.5C0.5 13.0779 0.799895 14.5589 1.39968 15.9432C1.99947 17.3274 2.82902 18.552 3.88833 19.6168C4.94764 20.6816 6.1714 21.5117 7.55962 22.107C8.94785 22.7023 10.4256 23 11.9927 23ZM11.9927 21.3011C10.6384 21.3011 9.36867 21.0495 8.18359 20.5461C6.99853 20.0427 5.95857 19.3409 5.06373 18.4406C4.16888 17.5404 3.46993 16.4974 2.96687 15.3116C2.46383 14.1257 2.21232 12.8552 2.21232 11.5C2.21232 10.1448 2.46383 8.87427 2.96687 7.68845C3.46993 6.50264 4.16888 5.4596 5.06373 4.55933C5.95857 3.65908 6.99611 2.95728 8.17634 2.45393C9.35656 1.95055 10.6239 1.69887 11.9782 1.69887C13.3423 1.69887 14.6168 1.95055 15.8019 2.45393C16.987 2.95728 18.0269 3.65908 18.9218 4.55933C19.8166 5.4596 20.5204 6.50264 21.0331 7.68845C21.5458 8.87427 21.8022 10.1448 21.8022 11.5C21.8022 12.8552 21.5483 14.1257 21.0404 15.3116C20.5325 16.4974 19.8311 17.5404 18.9363 18.4406C18.0414 19.3409 17.0015 20.0427 15.8164 20.5461C14.6313 21.0495 13.3568 21.3011 11.9927 21.3011Z" fill="#9E9FA0"/>
              <path d="M7.37815 12.3567H16.6072C16.8877 12.3567 17.1151 12.2841 17.2892 12.1389C17.4634 11.9937 17.5504 11.7904 17.5504 11.5291C17.5504 11.258 17.4658 11.0475 17.2965 10.8974C17.1272 10.7474 16.8974 10.6724 16.6072 10.6724H7.37815C7.08792 10.6724 6.85574 10.7474 6.68161 10.8974C6.50748 11.0475 6.42041 11.258 6.42041 11.5291C6.42041 11.7904 6.50989 11.9937 6.68886 12.1389C6.86785 12.2841 7.09761 12.3567 7.37815 12.3567Z" fill="#9E9FA0"/>
            </svg>

          <div *ngIf="collectionEditOptions?.mode === 'active-edit'" class="reorder-thumb-btn" cdkDragHandle>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
              <path d="M0.791883 12H23.1929C23.4162 12 23.6066 11.9227 23.7639 11.7681C23.9213 11.6135 24 11.4264 24 11.207C24 10.9875 23.9213 10.8005 23.7639 10.6459C23.6066 10.4913 23.4162 10.414 23.1929 10.414H0.791883C0.568537 10.414 0.380714 10.4913 0.228413 10.6459C0.0761377 10.8005 0 10.9875 0 11.207C0 11.4264 0.0761377 11.6135 0.228413 11.7681C0.380714 11.9227 0.568537 12 0.791883 12ZM0.791883 6.79301H23.1929C23.4162 6.79301 23.6066 6.7157 23.7639 6.5611C23.9213 6.40649 24 6.21946 24 6.00002C24 5.77058 23.9213 5.58105 23.7639 5.43143C23.6066 5.28181 23.4162 5.20699 23.1929 5.20699H0.791883C0.568537 5.20699 0.380714 5.28181 0.228413 5.43143C0.0761377 5.58105 0 5.77058 0 6.00002C0 6.21946 0.0761377 6.40649 0.228413 6.5611C0.380714 6.7157 0.568537 6.79301 0.791883 6.79301ZM0.791883 1.57106H23.1929C23.4162 1.57106 23.6066 1.49625 23.7639 1.34663C23.9213 1.197 24 1.01247 24 0.793022C24 0.573554 23.9213 0.386516 23.7639 0.23191C23.6066 0.0773037 23.4162 0 23.1929 0H0.791883C0.568537 0 0.380714 0.0773037 0.228413 0.23191C0.0761377 0.386516 0 0.573554 0 0.793022C0 1.01247 0.0761377 1.197 0.228413 1.34663C0.380714 1.49625 0.568537 1.57106 0.791883 1.57106Z" fill="#9E9FA0"/>
            </svg>
          </div>

          <svg *ngIf="isCardPurchasedCheckById(thumb) && collectionEditOptions?.mode !== 'active-edit'" class="purchased-model-icon" width="16" height="16" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4927 0.503218C13.0696 0.503218 14.5522 0.80084 15.9404 1.39609C17.3286 1.99133 18.5524 2.82128 19.6117 3.88595C20.671 4.95062 21.5005 6.17499 22.1003 7.55905C22.7001 8.94311 23 10.424 23 12.0016C23 13.5792 22.7001 15.0601 22.1003 16.4442C21.5005 17.8282 20.6686 19.0526 19.6044 20.1173C18.5403 21.1819 17.3165 22.0119 15.9331 22.6071C14.5497 23.2024 13.0648 23.5 11.4782 23.5C9.91104 23.5 8.43333 23.2024 7.04511 22.6071C5.6569 22.0119 4.43555 21.1819 3.38108 20.1173C2.3266 19.0526 1.49947 17.8282 0.899684 16.4442C0.299895 15.0601 0 13.5792 0 12.0016C0 10.424 0.299895 8.94311 0.899684 7.55905C1.49947 6.17499 2.32902 4.95062 3.38833 3.88595C4.44764 2.82128 5.6714 1.99133 7.05962 1.39609C8.44785 0.80084 9.92556 0.503218 11.4927 0.503218ZM11.4927 2.20184C10.1384 2.20184 8.86867 2.45349 7.68359 2.95679C6.49853 3.46008 5.45857 4.1618 4.56373 5.06193C3.66888 5.96206 2.96993 7.00494 2.46687 8.19058C1.96383 9.37624 1.71232 10.6466 1.71232 12.0016C1.71232 13.3566 1.96383 14.627 2.46687 15.8126C2.96993 16.9983 3.66888 18.0412 4.56373 18.9413C5.45857 19.8414 6.49611 20.5431 7.67634 21.0464C8.85656 21.5497 10.1239 21.8014 11.4782 21.8014C12.8423 21.8014 14.1168 21.5497 15.3019 21.0464C16.487 20.5431 17.5269 19.8414 18.4218 18.9413C19.3166 18.0412 20.0204 16.9983 20.5331 15.8126C21.0458 14.627 21.3022 13.3566 21.3022 12.0016C21.3022 10.6466 21.0483 9.37624 20.5404 8.19058C20.0325 7.00494 19.3311 5.96206 18.4363 5.06193C17.5414 4.1618 16.5015 3.46008 15.3164 2.95679C14.1313 2.45349 12.8568 2.20184 11.4927 2.20184Z" fill="#828282"/>
            <path d="M11.4922 6.33954C11.7147 6.33954 11.8961 6.40971 12.0363 6.55005C12.1766 6.69041 12.2468 6.8719 12.2468 7.0945V13.2502L12.1742 15.5005L13.3786 14.1213L14.8587 12.6114C15.0039 12.4662 15.1828 12.3937 15.3957 12.3937C15.6085 12.3937 15.785 12.4614 15.9253 12.5969C16.0656 12.7324 16.1357 12.9018 16.1357 13.105C16.1357 13.318 16.0632 13.4922 15.918 13.6277L12.0726 17.475C11.8888 17.6686 11.6953 17.7654 11.4922 17.7654C11.289 17.7654 11.0955 17.6686 10.9117 17.475L7.05179 13.6277C6.91635 13.4922 6.84863 13.318 6.84863 13.105C6.84863 12.9018 6.91877 12.7324 7.05904 12.5969C7.19931 12.4614 7.37103 12.3937 7.57418 12.3937C7.78701 12.3937 7.96598 12.4662 8.11111 12.6114L9.62025 14.1213L10.8102 15.486L10.7231 13.2502V7.0945C10.7231 6.8719 10.7956 6.69041 10.9407 6.55005C11.0859 6.40971 11.2697 6.33954 11.4922 6.33954Z" fill="#BDBDBD"/>
          </svg>

      </div>
    </div>

  </div>
</div>
<app-fullsize-dragdrop [collectionToUploadId]="sourceCollection.collectionId"></app-fullsize-dragdrop>
<div class="dark-background-overscroll">
  <div class="dark-top-gradient"></div>
  <div class="dark-center"></div>
</div>
