<div class="navpanel-overlay" (click)="onOverlayClicked()" ></div>
<div class="navpanel-wrapper" (swipedown)="onSwipe()">
  <div class="navpanel-top-divider"></div>


  <div class="navpanel-content-row" fxLayout="column"   fxLayoutAlign="space-between start" >
    <div class="navpanel-action-item" [ngClass]="navpanelAction.actionType" *ngFor="let navpanelAction of navpanelActionItems" [hidden]="navpanelAction.optionalData?.disabled" (click)="navpanelActionItemClick(navpanelAction)">
      <ng-container *ngIf="navpanelAction.actionType !== NAVPANEL_ACTION_TYPE.UPLOAD_MODEL && navpanelAction.actionType !== NAVPANEL_ACTION_TYPE.ADD_VIDEO && navpanelAction.actionType !== NAVPANEL_ACTION_TYPE.CONTACT_US">
        <span [ngClass]="navpanelAction.actionType" *ngFor="let word of convertToStringArray(navpanelAction.actionName)">{{word}}&nbsp;</span>
        <!-- <span *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.NEW_COLLECTION" class="additional-span">in Portfolio</span> -->
        <svg *ngIf="navpanelAction.isSelected" class="nav-checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 8.94766L5.2 13.1477L15 3.34766" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>

      </ng-container>
      <ng-container *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.CONTACT_US">
        <a class="contact-us-link" href="{{utilityService.generateHref()}}" (click)="utilityService.logContactUsClick()"><span>Contact</span> Us</a>

      </ng-container>
      <!-- UPLOAD TYPE BTN -->
      <ng-container *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL">
         <ng-container *ngIf="settingsService.devicePlatform === 'ios'">
            <span (click)="handleUploadBtnClick($event, navpanelAction)" [ngClass]="navpanelAction.actionType" *ngFor="let word of convertToStringArray(navpanelAction.actionName)">{{word}}&nbsp;</span>
         </ng-container>
         <ng-container *ngIf="settingsService.devicePlatform !== 'ios'">
          <label for="file-upload" class="button-upload-container">
            <span class="library-upload" *ngFor="let word of convertToStringArray(navpanelAction.actionName)">{{word}}&nbsp;</span>
          </label>
            <input #fileinput type="file"
            multiple
            id="file-upload"
            class="file-input"
            accept="model/vnd.pixar.usdz, model/usd, model/usda, model/usdc"
            (change)="handleFileInputNavpanel($event, navpanelAction)"
            >
         </ng-container>
      </ng-container>
      <ng-container *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.ADD_VIDEO">
        <svg *ngIf="navpanelAction.isSelected" class="nav-checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 8.94766L5.2 13.1477L15 3.34766" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <label for="file-upload" class="button-upload-container">
          <span class="library-upload" *ngFor="let word of convertToStringArray(navpanelAction.actionName)">{{word}}&nbsp;</span>
        </label>
          <input #fileinput type="file"
          multiple
          id="file-upload"
          class="file-input"
          accept="video/mp4,video/x-m4v,video/*"
          (change)="handleVideoInputNavpanel($event, navpanelAction)"
          >
      </ng-container>

    </div>
    <ng-content></ng-content>
  </div>
  <span class="close-navpanel" (click)="onCloseBtnClicked()">Close</span>
</div>

<!-- EXAMPLE OF OLD APPROACH: -->
<!-- <ng-container *ngIf="navpanelAction.actionType === NAVPANEL_ACTION_TYPE.UPLOAD_MODEL">
  <label for="file-upload" class="button-upload-container">
    <span class="library-upload" *ngFor="let word of convertToStringArray(navpanelAction.actionName)">{{word}}&nbsp;</span>
  </label>
    <input #fileinput type="file"
    multiple
    id="file-upload"
    class="file-input"
    accept="model/vnd.usdz+zip"
    (click)="closeNavpanel()"
    (change)="handleFileInputNavpanel($event, navpanelAction)"
    >
</ng-container> -->
