import { environment } from 'src/environments/environment';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PROFILE_REGION } from 'src/app/models/user';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { WebNavService } from '../webnavigation.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { INavpanelAction, NAVPANEL_ACTION_TYPE, SUBSCRIPTION_STATUS } from 'src/app/models/card';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';

@Component({
  selector: 'app-webaccount',
  templateUrl: './webaccount.component.html',
  styleUrls: ['./webaccount.component.scss'],
  animations: [
    trigger('slideToggle', [
      transition(':enter', [
        style({ height: 0, opacity: 0, overflow: 'hidden' }), // Start with no height and transparent
        animate('0.2s ease-out', style({ height: '*', opacity: 1 })) // Animate to the natural height and full opacity
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1, 'padding-bottom': '20px',  overflow: 'hidden' }), // Start with current height and full opacity
        animate('0.2s ease-out', style({ height: 0, opacity: 0, 'padding-bottom': '0px' })) // Animate to no height and transparent
      ])
    ])
  ]
})
export class WebaccountComponent implements OnInit {
  @Output() webAccountPopupEvent = new EventEmitter<INavpanelAction>();


  isAccountRegionShown: boolean;
  isSubscriptionRegionShown: boolean;
  environment = environment;

  PROFILE_REGION = PROFILE_REGION;
  isRenderLimitRegionShown: boolean;

  constructor(
    public userDataService: UserDataService,
    public settingsService: SettingsService,
    public webNavService: WebNavService,
    public firebaseAuth: FirebaseAuthService,
    public cardsService: CardsService,
    public utilityService: UtilityFunctionsService,
    private dialogRef: MatDialogRef<WebaccountComponent>
  ) {

  }

  ngOnInit(): void {
  }

  onMainBtnClick(): void {
    console.log('onMainBtnClick');

  }

  public deleteAccClick() {
    this.webNavService.deleteAccRequest();
  }

  public cancelSub() {
    console.log('cancelSub clicked')
    this.firebaseAuth.isSubscriptionRelatedBtnDisabled = true;
    this.cardsService.stripeCancelSubscription();
    this.dialogRef.close();
  }

  public isSubscriber(): boolean {
    return this.firebaseAuth.currentUser?.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER;
  }

  public subscribe() {
    setTimeout(() => {
    this.webAccountPopupEvent.emit({ actionName: 'Subscribe', actionType: NAVPANEL_ACTION_TYPE.SHOW_SUBSCRIBE });
    }, 350);
    this.dialogRef.close();
  }

  public renew() {
    console.log('renew subscription clicked')
    this.firebaseAuth.isSubscriptionRelatedBtnDisabled = true;
    this.cardsService.stripeRenewSubscription();
    this.dialogRef.close();
  }

  public betaSettings() {
    setTimeout(() => {
      this.webAccountPopupEvent.emit({ actionName: 'Settings', actionType: NAVPANEL_ACTION_TYPE.SETTINGS });
      }, 350);
      this.dialogRef.close();
  }

  public signOut() {
    console.log('signOut')
    this.webNavService.signOut();
    this.dialogRef.close();
  }

  public getFormattedEndDate(): string {
    const subscriptionEndDate = new Date(this.firebaseAuth.currentUser.subscriptionEndDateTime);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return subscriptionEndDate.toLocaleDateString('en-US', options);
  }

  public toggleRegion(event: Event, region: string) {
    console.log('region click')
    event.stopPropagation();
    switch (region) {
      case PROFILE_REGION.PERSONAL:
        this.isAccountRegionShown = !this.isAccountRegionShown;
        this.isSubscriptionRegionShown = false;
        this.isRenderLimitRegionShown = false;
        break;
      case PROFILE_REGION.SUBSCRIPT:
        this.isSubscriptionRegionShown = !this.isSubscriptionRegionShown;
        this.isAccountRegionShown = false;
        this.isRenderLimitRegionShown = false;

        break;
      case PROFILE_REGION.RENDER_LIMIT:
        this.isRenderLimitRegionShown = !this.isRenderLimitRegionShown;
        this.isAccountRegionShown = false;
        this.isSubscriptionRegionShown = false;
        break;

      default:
        break;
    }
    return
  }

}
